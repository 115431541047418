import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    Image,
    ScrollView,
    TouchableOpacity,
    Share,
    Linking,
    ActivityIndicator, TextInput, NativeSyntheticEvent, TextInputKeyPressEventData, Platform
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import axios from "axios";
import Api from "../constants/Api";
import FlashMessage, {showMessage} from "react-native-flash-message";
import qs from "querystring";
import Helper from "../components/Helper";

export default function SearchScreen({navigation}: RootStackScreenProps<'Search'>) {
    const [memos, onChangeMemos] = React.useState('');
    const [actualite, onChangeActualite] = React.useState('');
    const [quiz, onChangeQuiz] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const listMemos = [];
    const listActualites = [];
    const listQuiz = [];
    const [search, onChangeSearch] = React.useState('');
    let noContent = <View style={styles.itemEmpty}></View>;

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const updateSearch = async (loader: boolean, ev) => {
        onChangeSearch(ev)
        if(loader) {
            setLoading(true);
        }
        const access_token = await AsyncStorage.getItem('access_token')
        const payload = {
            search
        };
        await axios.post(`${Api.baseUrl}/api/search`, qs.stringify(payload),{
            headers: {
                'Authorization' : 'Bearer ' + access_token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(
            function (response) {
                onChangeActualite(response.data.actu)
                onChangeMemos(response.data.memo)
                onChangeQuiz(response.data.quiz)
                setTimeout(function() { setLoading(false);}, 50);
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    useEffect(() => {
        isConnected().then()
    }, []);

    for (let i = 0; i < memos.length; i++) {
        listMemos.push(
            <View style={styles.item}>
                <View>
                    <TouchableOpacity onPress={() => navigation.push('MemosDetail', {uuid: memos[i].uuid})}>
                        <Image style={styles.imageStyle}  source={{uri:memos[i].thumbnail}}/>
                    </TouchableOpacity>
                </View>
                <View style={styles.leftBlock}>
                    <TouchableOpacity onPress={() => navigation.push('MemosDetail', {uuid: memos[i].uuid})}>
                        <Text numberOfLines={2} style={styles.category}>Mémos</Text>
                        <Text numberOfLines={2} style={styles.title}>{memos[i].category}, {memos[i].title}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }

    for (let i = 0; i < actualite.length; i++) {
        listActualites.push(
            <View style={styles.item}>
                <View>
                    <TouchableOpacity onPress={() => navigation.push('ActuDetail', {uuid: actualite[i].uuid})}>
                        <Image style={styles.imageStyle}  source={{uri:actualite[i].thumbnail}}/>
                    </TouchableOpacity>
                </View>
                <View style={styles.leftBlock}>
                    <TouchableOpacity onPress={() => navigation.push('ActuDetail', {uuid: actualite[i].uuid})}>
                        <Text numberOfLines={2} style={styles.category}>Actualité</Text>
                        <Text numberOfLines={2} style={styles.title}>{actualite[i].title}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }

    for (let i = 0; i < quiz.length; i++) {
        listQuiz.push(
            <View style={styles.item}>
                <View>
                    <TouchableOpacity onPress={() => navigation.push('QuizDetail',  {uuid: quiz[i].uuid, category: quiz[i].key, category_name: quiz[i].category})}>
                        <Image style={styles.imageStyle} source={{uri:quiz[i].logo}}/>
                    </TouchableOpacity>
                </View>
                <View style={styles.leftBlock}>
                    <TouchableOpacity onPress={() => navigation.push('QuizDetail', {uuid: quiz[i].uuid, category: quiz[i].key, category_name: quiz[i].category})}>
                        <Text numberOfLines={2} style={styles.category}>Quiz</Text>
                        <Text numberOfLines={2} style={styles.title}>{quiz[i].title}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }

    if(quiz.length === 0 && actualite.length === 0 && memos.length === 0) {
        noContent =  <View style={styles.itemEmpty}>
            <Text style={styles.textEmpty}>Aucun résultat</Text>
        </View>
    }

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <View style={styles.containerTop}>
                    <TouchableOpacity onPress={() => navigation.navigate('Logged')} style={styles.back}>
                        <Image
                            source={require('./../assets/images/back-arrow.png')}/>
                    </TouchableOpacity>
                    <View style={styles.container2}>
                        <TextInput
                            style={[search ? styles.input : styles.placeholderT, search.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder=""
                            value={search}
                            placeholderTextColor={'#df5952'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={true}
                            onChangeText={(ev) => updateSearch(true, ev)}
                        />
                    </View>
                </View>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952" />
                    </View>
                ) : (
                    <ScrollView scrollsToTop={true} style={styles.insideContainer}>
                        {listQuiz}
                        {listMemos}
                        {listActualites}
                        {noContent}
                    </ScrollView>
                )}
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    containerTop: {
        height: 120
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    barre: {
        top: 55,
        left: 50,
    },
    container2: {
        flexDirection: 'column',
        height: 120,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
    },
    imageStyle: {
        width: 100,
        height: 120,
        resizeMode: "cover"
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    back: {
        flex: 1,
        position: 'absolute',
        left: 1,
        top: 50,
        zIndex: 10
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    textEmpty: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        fontSize: 16
    },
    insideContainer: {
        flex: 1,
        backgroundColor: '#FFF',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    item: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#d0d0d0',
        flex: 1,
        flexDirection: 'row'
    },
    itemEmpty: {
        padding: 10,
        borderBottomWidth: 0,
        borderBottomColor: '#d0d0d0',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    category : {
        fontSize: 18,
        fontFamily: "GothamBold",
        paddingTop: 10
    },
    title: {
        fontSize: 18,
        fontFamily: "Gotham",
    },
    leftBlock: {
        paddingLeft: 10,
        flexShrink: 1
    },
    actionContainer: {
        marginTop: 20,
        marginBottom: 10,
        flex: 1,
    },
    logo: {
       position: 'absolute',
        left: 210,
        width: 35,
        height: 35
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    input: {
        margin: 12,
        padding: 10,
        width: 280,
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        backgroundColor: '#F4F4F4',
        borderRadius: 10
    },
    placeholderT: {
        margin: 12,
        padding: 10,
        width: 280,
        height: 55,
        fontSize: 20,
        color: '#df5952',
        fontWeight: 'normal',
        fontFamily: "Gotham",
        backgroundColor: '#F4F4F4',
        borderRadius: 10,
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    inputBlack: {
        color: '#000'
    }
});

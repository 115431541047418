import {StatusBar} from 'expo-status-bar';
import React from 'react';
import {
    StyleSheet,
    Text,
    View,
    Image,
    ImageBackground,
    TouchableOpacity,
    TextInput,
    Platform,
    Dimensions
} from 'react-native';
import {RootStackScreenProps} from "../types";
import axios from 'axios';
import Api from '../constants/Api';
import qs from 'querystring';
import FlashMessage, {showMessage} from "react-native-flash-message";
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function PasswordScreen({navigation}: RootStackScreenProps<'Password'>) {
    const [email, onChangeText] = React.useState('');
    const checkFormData = () => email.length > 6;
    const isSmallDevice = Dimensions.get('window').width < 768
    const resetFct = async () => {
        const payload = {
            email: email.toLowerCase(),
        };
        const response = await axios.post(`${Api.baseUrl}/api/user/reset-password`, qs.stringify(payload), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(
            function (response) {
                showMessage({
                    message: "Consultez votre boite e-mail",
                    description: "Un email de re-initialisation vous a été envoyé.",
                    type: "success",
                    duration: 3000
                });
                navigation.navigate('Login')
            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est intervenue.",
                    type: "danger",
                });
            }
        )
    }

    const validate = (email: any) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase())
    }

    return (
        <View style={styles.container}>
            <ImageBackground source={require('./../assets/images/background-flou.png')} resizeMode="cover"
                             style={styles.image}>
                <View style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.containerh : styles.containerweb}>
                    <View style={styles.width20}>
                        <TouchableOpacity onPress={() => navigation.navigate('Login')} style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.backsmall : styles.back]}>
                            <Image
                                source={require('./../assets/images/back-arrow.png')} style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.backarrow : styles.backarrowweb}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.width60} >
                        <Image
                            style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logo : styles.logoweb}
                            source={require('./../assets/images/logo_capeb.png')}
                        />
                    </View>
                    <View style={styles.width20}>

                    </View>
                </View>
                <TextInput
                    style={[email ? styles.input : styles.placeholderT, validate(email) && email.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                    placeholder="Adresse e-mail"
                    onChangeText={onChangeText}
                    value={email}
                    textAlignVertical={'top'}
                    placeholderTextColor={'#686868'}
                    autoCorrect={false}
                    autoCompleteType='email'
                    autoCapitalize='none'
                />
                <Text style={[validate(email) || email.length === 0 ? styles.hideError : styles.showError]}>Adresse email invalide</Text>
                <TouchableOpacity
                    style={checkFormData() ? styles.roundButton2 : styles.roundButton2No}
                    disabled={!checkFormData()}
                    onPress={resetFct}>
                    <Text style={styles.textBold}>RÉINITIALISER</Text>
                </TouchableOpacity>
            </ImageBackground>
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    containerh: {
        flexDirection: 'row',
        height: 120,
        backgroundColor: '#000',
        width: '100%',
    },
    backarrow: {
        width: 50,
        height: 50,
        marginLeft: 0,
    },
    backarrowweb: {
        width: 50,
        height: 50,
        marginLeft: 0,
        marginTop: 6,
    },
    containerweb: {
        flexDirection: 'row',
        height: 90,
        backgroundColor: '#000',
        width: '100%'
    },
    logo: {
        width: 160,
        height: 60,
        marginLeft: -10,
        marginTop: 50,
    },
    logosmall: {
        width: 200,
        height: 70,
        marginTop: 45,
        alignItems: 'center'
    },
    back: {
        left: 1,
        top: 15,
        width: 40,
        height: 40,
    },
    backsmall: {
        left: 0,
        top: 56,
        width: 40,
        height: 40,
    },
    logoBack: {
        left: 5,
        top: 6,
        width: 35,
        height: 35
    },
    logoBackMobile: {
        left: 5,
        top: 32,
        zIndex: 10,
        width: 35,
        height: 48
    },
    logoweb: {
        width: 160,
        height: 60,
        marginLeft: -10,
        marginTop: 10,
    },
    width20: {
        width: '10%',
    },
    width60: {
        width: '80%',
        alignItems: 'center'
    },
    hideError: {
        display: 'none'
    },
    showError: {
        color: '#df5952',
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    image: {
        flex: 1,
        alignItems: 'center'
    },
    roundButton2: {
        marginTop: 40,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#df5952',
        bottom: 50,
        position: 'absolute',
        fontFamily: "GothamBold",
    },
    roundButton2No: {
        marginTop: 40,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#CECECE',
        bottom: 50,
        position: 'absolute',
        fontFamily: "GothamBold",
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    textMotDePasse: {
        color: 'black',
        fontSize: 16,
        margin: 20,
        fontFamily: "Gotham",
    },
    input: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "Gotham",
    },
    placeholderT: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        color: '#fff',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    inputBlack: {
        color: '#000'
    },
    header : {
        backgroundColor: '#313131',
        width: '100%',
        alignItems: 'center',
        height: 130,
        marginBottom: 50
    },
});

import {StatusBar} from 'expo-status-bar';
import React from 'react';
import {
    StyleSheet,
    Text,
    View,
    Image,
    ImageBackground,
    TouchableOpacity,
    TextInput,
    Platform, Dimensions, Pressable
} from 'react-native';
import {RootStackScreenProps} from "../types";
import {encode as btoa64} from 'base-64'
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {useTogglePasswordVisibility} from '../hooks/useTogglePasswordVisibility';

export default function RegisterScreen({navigation}: RootStackScreenProps<'Register'>) {
    const [email, onChangeText] = React.useState('');
    const [password, onChangePassword] = React.useState('');
    const [passwordConfirm, onChangePasswordConfirm] = React.useState('');
    const [company, onChangeText2] = React.useState('');
    const [siret, onChangeSiret] = React.useState('');
    const [phone, onChangePhone] = React.useState('');
    const isSmallDevice = Dimensions.get('window').width < 768
    const {passwordVisibility, rightIcon, handlePasswordVisibility} = useTogglePasswordVisibility();
    const checkFormData = () => company.length > 0 && email.length > 6 && validate(email) && password.length > 6 && siret.length > 0 && siret.length == 14 && phone.length > 9 && passwordConfirm.length > 6 && password == passwordConfirm;

    const validate = (email: any) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase())
    }

    function nextStep(email: string, password: string, company: string, siret: string, phone: string) {
        let email_encoded = btoa64(email);
        let password_encoded = btoa64(password);
        let company_encoded = btoa64(company);
        let siret_encoded = btoa64(siret);
        let phone_encoded = btoa64(phone);
        navigation.navigate('Register2', {
            email: email_encoded,
            password: password_encoded,
            company: company_encoded,
            siret: siret_encoded,
            phone: phone_encoded,
        });
    }

    return (
        <View style={styles.container}>
            <ImageBackground source={require('./../assets/images/background-flou.png')} resizeMode="cover"
                             style={styles.image}>
                <View
                    style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.containerh : styles.containerweb}>
                    <View style={styles.width20}>
                        <TouchableOpacity onPress={() => navigation.navigate('Home')}
                                          style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.backsmall : styles.back]}>
                            <Image
                                source={require('./../assets/images/back-arrow.png')}
                                style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.backarrow : styles.backarrowweb}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.width60}>
                        <Image
                            style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logo : styles.logoweb}
                            source={require('./../assets/images/logo_capeb.png')}
                        />
                    </View>
                    <View style={styles.width20}>

                    </View>
                </View>
                <TextInput
                    style={[company ? styles.input : styles.placeholderT, styles.topNeg, styles.inputBlack]}
                    placeholder="Le nom de votre société *"
                    onChangeText={onChangeText2}
                    value={company}
                    placeholderTextColor={'#686868'}
                    autoCapitalize='none'
                    autoCorrect={false}
                />
                <TextInput
                    style={[siret ? styles.input : styles.placeholderT, siret.length != 14 && siret.length > 0 ? styles.inputKO : styles.inputOK, styles.top, styles.inputBlack]}
                    placeholder="Votre numéro de SIRET *"
                    onChangeText={onChangeSiret}
                    value={siret}
                    placeholderTextColor={'#686868'}
                    autoCapitalize='none'
                    keyboardType='numeric'
                    autoCorrect={false}
                    maxLength={14}
                />
                <Text style={[siret.length != 14 && siret.length > 0 ? styles.showError : styles.hideError]}>Numéro de SIRET invalide</Text>
                <TextInput
                    style={[phone ? styles.input : styles.placeholderT, phone.length != 10 && phone.length > 0 ? styles.inputKO : styles.inputOK, styles.top, styles.inputBlack]}
                    placeholder="Votre numéro de téléphone *"
                    onChangeText={onChangePhone}
                    value={phone}
                    placeholderTextColor={'#686868'}
                    autoCapitalize='none'
                    keyboardType='numeric'
                    autoCorrect={false}
                    maxLength={10}
                />
                <Text style={[phone.length != 10 && phone.length > 0 ? styles.showError : styles.hideError]}>Numéro de téléphone invalide</Text>
                <TextInput
                    style={[email ? styles.input : styles.placeholderT, !validate(email) && email.length > 0 ? styles.inputKO : styles.inputOK, styles.top, styles.inputBlack]}
                    placeholder="Votre adresse e-mail *"
                    onChangeText={onChangeText}
                    value={email}
                    placeholderTextColor={'#686868'}
                    autoCapitalize='none'
                    autoCorrect={false}
                    autoCompleteType='email'
                />
                <Text style={[validate(email) || email.length === 0 ? styles.hideError : styles.showError]}>Adresse
                    email invalide</Text>
                <View style={styles.inputContainerPassword}>
                    <TextInput
                        onChangeText={onChangePassword}
                        value={password}
                        placeholder="Votre mot de passe *"
                        style={[password ? styles.inputPassword : styles.placeholderPassword, password.length < 6 && password.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                        secureTextEntry={passwordVisibility}
                        placeholderTextColor={'#686868'}
                        autoCorrect={false}
                        autoCompleteType='password'
                    />
                    <Pressable onPress={handlePasswordVisibility}>
                        <MaterialCommunityIcons name={rightIcon} size={22} color="#232323"/>
                    </Pressable>
                </View>
                <Text style={[password.length === 0 || password.length > 6 ? styles.hideError : styles.showError]}>Mot
                    de passe trop court</Text>
                <View style={styles.inputContainerPassword}>
                    <TextInput
                        style={[passwordConfirm ? styles.inputPassword : styles.placeholderPassword, passwordConfirm.length < 6 && passwordConfirm.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                        onChangeText={onChangePasswordConfirm}
                        value={passwordConfirm}
                        placeholder="Confirmer votre mot de passe *"
                        secureTextEntry={passwordVisibility}
                        placeholderTextColor={'#686868'}
                        autoCorrect={false}
                        autoCompleteType='password'
                    />
                    <Pressable onPress={handlePasswordVisibility}>
                        <MaterialCommunityIcons name={rightIcon} size={22} color="#232323"/>
                    </Pressable>
                </View>
                <Text
                    style={[passwordConfirm.length === 0 || passwordConfirm.length > 6 ? styles.hideError : styles.showError]}>Confirmation
                    de mot de passe trop courte</Text>
                <Text
                    style={[passwordConfirm.length > 6 && password.length > 6 && password != passwordConfirm ? styles.showError : styles.hideError]}>Les
                    mots de passe ne correspondent pas</Text>
                <TouchableOpacity
                    onPress={() => nextStep(email, password, company, siret, phone)}
                    disabled={!checkFormData()}
                    style={checkFormData() ? styles.roundButton2 : styles.roundButton2No}>
                    <Text style={styles.textBold}>SUIVANT</Text>
                </TouchableOpacity>
            </ImageBackground>
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'}/>
        </View>
    );
}

const styles = StyleSheet.create({
    containerh: {
        flexDirection: 'row',
        height: 120,
        backgroundColor: '#000',
        width: '100%',
    },
    backarrow: {
        width: 50,
        height: 50,
        marginLeft: 0,
    },
    backarrowweb: {
        width: 50,
        height: 50,
        marginLeft: 0,
        marginTop: 6,
    },
    containerweb: {
        flexDirection: 'row',
        height: 90,
        backgroundColor: '#000',
        width: '100%'
    },
    logo: {
        width: 160,
        height: 60,
        marginLeft: -10,
        marginTop: 50,
    },
    logosmall: {
        width: 200,
        height: 70,
        marginTop: 45,
        alignItems: 'center'
    },
    back: {
        left: 1,
        top: 15,
        width: 40,
        height: 40,
    },
    backsmall: {
        left: 0,
        top: 56,
        width: 40,
        height: 40,
    },
    logoBack: {
        left: 5,
        top: 6,
        width: 35,
        height: 35
    },
    logoBackMobile: {
        left: 5,
        top: 32,
        zIndex: 10,
        width: 35,
        height: 48
    },
    logoweb: {
        width: 160,
        height: 60,
        marginLeft: -10,
        marginTop: 10,
    },
    width20: {
        width: '10%',
    },
    width60: {
        width: '80%',
        alignItems: 'center'
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    hideError: {
        display: 'none'
    },
    showError: {
        marginTop: 18,
        height: 16,
        color: '#df5952',
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#df5952'
    },
    image: {
        flex: 1,
        alignItems: 'center'
    },
    roundButton2: {
        marginTop: 40,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#df5952',
        fontFamily: "GothamBold",
    },
    roundButton2No: {
        marginTop: 40,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#CECECE',
        fontFamily: "GothamBold",
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    inputTopMargin: {
        marginTop: 20,
    },
    input: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "Gotham",
    },
    inputPassword: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 340,
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "Gotham",
    },
    placeholderT: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        color: '#000',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    placeholderPassword: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 340,
        height: 55,
        fontSize: 20,
        color: '#000',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    textBottomStep: {
        marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        bottom: 10,
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    top: {
        marginTop: 10,
    },
    inputBlack: {
        color: '#000',
    },
    topNeg: {
        marginTop: 10,
    },
    header: {
        backgroundColor: '#313131',
        width: '100%',
        alignItems: 'center',
        height: 130,
        marginBottom: 50
    },
    inputContainerPassword: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
    SafeAreaView, Platform, Alert, ScrollView
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import SelectPicker from "react-native-form-select-picker";
import axios from "axios";
import Api from "../constants/Api";
import qs from "querystring";
import Checkbox from 'react-native-checkbox';
import FlashMessage, {showMessage} from "react-native-flash-message";
import MultiSelect from 'react-native-multiple-select';
import Icon from "react-native-vector-icons/FontAwesome";
import Helper from "../components/Helper";

export default function MemberScreen({route, navigation}: RootStackScreenProps<'Member'>) {
    const [registry, onChangeRegistry] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const listRegistry = [];
    const [search, onChangeSearch] = React.useState('');
    const [distance, onChangeDistance] = React.useState('');
    const [location, onChangeLocation] = React.useState('');
    let noContent = <View style={styles.itemEmpty}/>;

    // @ts-ignore
    const {uuid} = route.params;
    const {back} = route.params;


    const updateSearch = async (loader: boolean, ev: string) => {
        onChangeSearch(ev)
        await dataFct(false, ev, location, distance)
    }

    const updateLocation = async (loader: boolean, ev: string) => {
        onChangeLocation(ev)
        await dataFct(false, search, ev, distance)
    }

    const updateDistance = async (loader: boolean, ev: string) => {
        onChangeDistance(ev)
        await dataFct(false, search, location, ev)
    }

    const dataFct = async (loader: boolean, search: string, location: string, distance: string) => {
        if (loader) {
            setLoading(true);
        }
        const access_token = await AsyncStorage.getItem('access_token')
        let payload = {uuid}
        // @ts-ignore
        payload = {search, location, distance, uuid}
        await axios.post(`${Api.baseUrl}/api/member`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeRegistry(response.data.registry)
                setTimeout(function () {
                    setLoading(false);
                }, 50);
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    const addUser = async (user_uuid: string) => {
        const access_token = await AsyncStorage.getItem('access_token')
        let payload = {uuid, user_uuid}
        await axios.post(`${Api.baseUrl}/api/add-member`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                setLoading(false);
                showMessage({
                    message: "Succès !",
                    description: "Le membre à bien été ajouté !",
                    type: "success",
                    duration: 3000
                });
                // @ts-ignore
                navigation.navigate(backTo(), {uuid: uuid})
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    const inviteUser = async () => {
        //uuid
        navigation.navigate('InviteUser', {uuid: uuid})
    }

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    function backTo() {
        if(back && back.length > 0) {
            return back
        } else {
            return 'Logged'
        }

    }

    useEffect(() => {
        isConnected().then()
        dataFct(true, '').then()
    }, []);

    for (let i = 0; i < registry.length; i++) {
        listRegistry.push(
            <View style={styles.item}>
                <Text style={styles.category}>{registry[i].firstname} {registry[i].lastname}</Text>
                <Text style={styles.category2}>Société {registry[i].company}</Text>
                <Text style={styles.category3}>{registry[i].job} dans le département {registry[i].dpt}</Text>
                <Text style={styles.category4}>{registry[i].description}</Text>
                <TouchableOpacity onPress={() => { addUser(registry[i].uuid) }} style={styles.flex2}>
                    <Icon name="plus" size={30} color="#FFF" style={[registry[i].isProject ? styles.hide : styles.icon]}/>
                </TouchableOpacity>
            </View>
        );
    }

    if (registry.length > 0) {
        listRegistry.push(
            <View style={styles.item}>
                <TouchableOpacity  onPress={() => { inviteUser() }} style={styles.flex3}>
                    <Icon name="plus" size={30} color="#df5952" style={styles.icon}/>
                    <Text style={styles.invite}>Inviter un utilisateur ?</Text>
                </TouchableOpacity>

            </View>
        );
    }

    if (registry.length === 0) {
        noContent = <View style={styles.itemEmpty}>
            <Text style={styles.textEmpty}>Rechercher un contact...</Text>
        </View>
    }

    // @ts-ignore
    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="MEMBRES" titleTextTop="" navigation={navigation} backTo={backTo()} paramBack={{'uuid': uuid}}/>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <View style={styles.insideContainer}>
                        <TextInput
                            style={[location ? styles.input : styles.placeholderT, location.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder="Ville ? Code postal ?"
                            value={location}
                            placeholderTextColor={'#484848'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={false}
                            onChangeText={(ev) => updateLocation(false, ev)}
                        />
                        <TextInput
                            style={[search ? styles.input : styles.placeholderT, search.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder="Métier(s) ? Spécialité(s) ?"
                            value={search}
                            placeholderTextColor={'#484848'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={false}
                            onChangeText={(ev) => updateSearch(false, ev)}
                        />
                        <TextInput
                            style={[distance ? styles.input : styles.placeholderT, distance.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder="Rayon en kilomètre(s) ?"
                            value={distance}
                            placeholderTextColor={'#484848'}
                            keyboardType='numeric'
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={false}
                            onChangeText={(ev: string) => updateDistance(false, ev)}
                            maxLength={4}
                        />
                        {listRegistry}
                        {noContent}
                    </View>
                )}
            </View>
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    flex3: {
        flexDirection: 'row'
    },
    hide: {
        display: 'none'
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    invite: {
        paddingTop: 8,
        paddingLeft: 8,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        fontSize: 18,
        color: '#C6362D',
    },
    hideError: {
        display: 'none'
    },
    showError: {
        color: '#df5952',
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    image: {
        flex: 1,
        alignItems: 'center'
    },
    back: {
        flex: 1,
        position: 'absolute',
        left: 1,
        top: 72
    },
    textB: {
        fontSize: 16,
        padding: 0,
        marginTop: -4,
        fontFamily: "Gotham",
    },
    logo: {
        width: 200,
        height: 70,
        margin: 70,
    },
    roundButton2: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#df5952',
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40
    },
    roundButton2No: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#CECECE',
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40
    },
    textBlock: {
        margin: 25,
        padding: 15,
        width: '100%',
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    input: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 2,
        padding: 10,
        width: '95%',
        alignSelf: 'center',
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    placeholderT: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 2,
        padding: 10,
        width: '95%',
        alignSelf: 'center',
        height: 55,
        fontSize: 20,
        color: '#484848',
        fontWeight: 'normal',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    textBottomStep: {
        marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        bottom: 10,
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    placeholder: {
        fontSize: 20,
        color: '#686868',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    selected: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    select: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    containerMultiple: {
        marginTop: 5,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        height: 1
    },
    containerListJob: {
        marginTop: 80,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    selectMultiple2: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000'
    },
    selectMultiple2Dp: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000',
    },
    selectMultiple2DpSelectd: {
        height: 180,
    },
    selectM: {
        margin: 12,
        marginTop: 5,
        borderBottomWidth: 1,
        padding: 0,
        paddingLeft: 8,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
        color: '#000'
    },
    inputBlack: {
        color: '#000'
    },
    delete: {
        marginTop: 15,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        marginBottom: 20,
        backgroundColor: '#df5952',
        fontFamily: "GothamBold",
    },
    checkboxContainer1: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 20,
        width: 360,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 10,
        width: 360,
    },
    checkbox: {

    },
    labelCheckbox: {
        margin: 8,
        fontFamily: "Gotham",
        color: '#000'
    },
    itemEmpty: {
        padding: 10,
        borderBottomWidth: 0,
        borderBottomColor: '#d0d0d0',
        flex: 1,
    },
    textEmpty: {
        marginTop: 0,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        fontSize: 16,
    },
    item: {
        padding: 10,
        paddingBottom: 15,
        width: '95%',
        borderRadius: 5,
        backgroundColor: '#222220',
        margin: 5,
        alignSelf: 'center',
    },
    category: {
        fontSize: 20,
        fontFamily: "GothamBold",
        marginTop: 10,
        color: 'white'
    },
    category2: {
        fontSize: 17,
        fontFamily: "Gotham",
        marginTop: 10,
        color: 'white'
    },
    category3: {
        fontSize: 14,
        fontFamily: "Gotham",
        marginTop: 10,
        color: 'white'
    },
    category4: {
        fontSize: 14,
        fontFamily: "Gotham",
        marginTop: 10,
        marginBottom: 10,
        color: 'white'
    },
    icon: {
        marginTop: 5,
        marginLeft: 3
    }
});

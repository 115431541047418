import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
    Platform, Alert, ScrollView, Keyboard
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import SelectPicker from "react-native-form-select-picker";
import axios from "axios";
import Api from "../constants/Api";
import qs from "querystring";
import Checkbox from 'expo-checkbox';
import FlashMessage, {showMessage} from "react-native-flash-message";
import MultiSelect from 'react-native-multiple-select';
import {decode as atob64} from "base-64";
import Helper from "../components/Helper";

export default function ProfileScreen({navigation}: RootStackScreenProps<'Profile'>) {
    const [email, onChangeText] = React.useState('');
    const [company, onChangeCompany] = React.useState('');
    const [jobs, setJobs] = React.useState('');
    const [isAnnuaire, setAnnuaire] = React.useState(false);
    const [isCapeb, setCapeb] = React.useState(false);
    const [department, setDepartment] = React.useState();
    const [siret, onChangeSiret] = React.useState('');
    const [phone, onChangePhone] = React.useState('');
    const [firstname, onChangeFirstname] = React.useState('');
    const [lastname, onChangeLastname] = React.useState('');
    const [description, onChangeDescription] = React.useState('');
    const [capebNumber, onChangeCapebNumber] = React.useState('');
    const [credits, onChangeCredit] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const checkFormData = () => company && company.length > 0  && lastname && lastname.length > 0  &&  phone.length  == 10 &&  phone.length > 0 &&  siret.length  == 14 &&  siret.length > 0  && phone.length && firstname && firstname.length > 0 && department && department.length > 0 && selectedItems && selectedItems.length > 0;

    const validate = (email: any) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase())
    }

    const [data, setData] = React.useState({
            jobs: [{
                id: 0,
                name: ''
            }],
            dept: [{
                id: 0,
                name: ''
            }]
        }
    );

    const [selectedItems, setSelectedItems] = React.useState([]);

    const onSelectedItemsChange = (selectedItems: React.SetStateAction<never[]>) => {
        setSelectedItems(selectedItems);
    };

    const alertPolyfill = (title, description, options, extra) => {
        const result = window.confirm([title, description].filter(Boolean).join('\n'))

        if (result) {
            const confirmOption = options.find(({ style }) => style !== 'cancel')
            confirmOption && confirmOption.onPress()
        } else {
            const cancelOption = options.find(({ style }) => style === 'cancel')
            cancelOption && cancelOption.onPress()
        }
    }

    const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

    const saveAccount = async () => {
        const payload = {
            company,
            jobs: selectedItems.join(','),
            siret,
            isAnnuaire,
            isCapeb,
            phone,
            firstname,
            lastname,
            department,
            description,
            capebNumber
        };
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.post(`${Api.baseUrl}/api/user/update`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                if (response.data.status === 0) {
                    showMessage({
                        message: "Merci !",
                        description: "Vos données ont bien été mises à jours",
                        type: "success"
                    });
                } else if (response.data.status === 2) {
                    showMessage({
                        message: "Oups !",
                        description: "Votre numéro d'adhérent est invalide.",
                        type: "danger",
                    });
                }

            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    const deleteAccount = async () => {
        return alert(
            "Suppression de votre compte",
            "Êtes-vous certain de vouloir supprimer votre compte ?",
            [
                {
                    text: "Oui",
                    onPress: () => {
                        deleteActionGet()
                    },
                },
                {
                    text: "Non",
                },
            ]
        );
    }

    async function deleteActionGet() {
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/user/delete`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            async function (response) {
                await AsyncStorage.setItem('access_token', '')
                showMessage({
                    message: "Merci !",
                    description: "Vos données ont bien été supprimés",
                    type: "success"
                });
                navigation.navigate('Home')
            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    function getDataJob() {
        if (selectedItems && selectedItems.length > 0) {
            let result = '';
            for (let i = 0; i < selectedItems.length; i++) {
                //result += items[items.findIndex(item => item.id === selectedItems[i])].name + '\n';
            }
            return result;
        }
    }

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const getData = async () => {
        setLoading(true);
        await axios.get(`${Api.baseUrl}/api/constants`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(
            async function (response) {
                if (response.data.data) {
                    await setData(response.data.data)
                }
            }
        );
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/user`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                if (response.data.user) {
                    onChangeText(response.data.user.email)
                    if(response.data.user && response.data.user.company) {
                        onChangeCompany(response.data.user.company)
                    }
                    setSelectedItems(response.data.user.jobs)
                    setDepartment(response.data.user.dept)
                    setAnnuaire(response.data.user.isAnnuaire)
                    setCapeb(response.data.user.isCapeb)
                    onChangeSiret(response.data.user.siret)
                    onChangeFirstname(response.data.user.firstname)
                    onChangeLastname(response.data.user.lastname)
                    onChangePhone(response.data.user.phone)
                    onChangeDescription(response.data.user.description)
                    onChangeCapebNumber(response.data.user.capebNumber)
                    onChangeCredit(response.data.user.credits)
                    setLoading(false);
                }
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    const handleChange = () => {

        console.log('The checkbox was toggled');

    };

    useEffect(() => {
        isConnected().then()
        getData().then(r => {
        });
    }, []);

    // @ts-ignore
    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="MON COMPTE" titleTextTop="" navigation={navigation}/>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <ScrollView>

                    <View style={styles.insideContainer}>
                        <TextInput
                            style={[email ? styles.input : styles.placeholderT, !validate(email) && email.length < 0 ? styles.inputKO : styles.inputOK, styles.inputBlack2]}
                            placeholder="Adresse e-mail *"
                            onChangeText={onChangeText}
                            value={email}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoCompleteType='email'
                            editable={false}
                        />
                        <Text style={[validate(email) || email.length === 0 ? styles.hideError : styles.showError]}>Adresse
                            email invalide.</Text>
                        <TextInput
                            style={[firstname ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Prénom *"
                            onChangeText={onChangeFirstname}
                            value={firstname}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <TextInput
                            style={[lastname ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Nom *"
                            onChangeText={onChangeLastname}
                            value={lastname}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <TextInput
                            style={[company ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Nom de l'entreprise *"
                            onChangeText={onChangeCompany}
                            value={company}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <TextInput
                            style={[siret ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Votre numéro de SIRET *"
                            onChangeText={onChangeSiret}
                            value={siret}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            maxLength={14}
                        />
                        <Text style={[siret.length != 14 && siret.length > 0 ? styles.showError : styles.hideError]}>Numéro de SIRET invalide</Text>
                        <TextInput
                            style={[phone ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Votre numéro de téléphone *"
                            onChangeText={onChangePhone}
                            value={phone}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            maxLength={10}
                        />
                        <Text style={[phone.length != 10 && phone.length > 0 ? styles.showError : styles.hideError]}>Numéro de téléphone invalide</Text>
                        <SelectPicker
                            onValueChange={(value) => {
                                setDepartment(value);
                            }}
                            placeholder="Votre département *"
                            placeholderStyle={styles.placeholder}
                            style={styles.select}
                            selected={department}
                            // @ts-ignore
                            onSelectedStyle={styles.selected}
                            doneButtonText={'OK'}
                        >
                            {Object.values(data.dept).map((val, index) => (
                                <SelectPicker.Item label={val.name} value={val.id} key={index}/>
                            ))}
                        </SelectPicker>
                        <MultiSelect
                            hideTags
                            items={data.jobs}
                            uniqueKey="id"
                            onSelectedItemsChange={onSelectedItemsChange}
                            selectedItems={selectedItems}
                            selectText="Métiers"
                            searchInputPlaceholderText="Rechercher un métier..."
                            tagRemoveIconColor="#000"
                            tagBorderColor="#000"
                            tagTextColor="#000"
                            selectedItemTextColor="#000"
                            selectedItemIconColor="#000"
                            itemTextColor="#000"
                            displayKey="name"
                            searchInputStyle={{
                                fontSize: 20,
                                color: '#df5952',
                                fontWeight: 'normal',
                                fontFamily: "Gotham"
                            }}
                            submitButtonColor="#636363"
                            submitButtonText="OK"
                            styleDropdownMenu={styles.selectM}
                            styleInputGroup={styles.selectM}
                            styleTextDropdown={styles.selectMultiple2Dp}
                            styleTextDropdownSelected={styles.selectMultiple2}
                            styleListContainer={styles.selectMultiple2DpSelectd}
                        />
                        <View style={styles.checkboxContainer}>
                            <Checkbox
                                style={styles.checkbox}
                                value={isCapeb}
                                onValueChange={setCapeb}
                            />
                            <Text style={styles.labelCheckbox}>Êtes vous adhérent de la CAPEB ?</Text>
                        </View>
                        <TextInput
                            style={[isCapeb ? styles.inputC : styles.inputTextAreNoneC]}
                            placeholder="Votre numéro CAPEB *"
                            onChangeText={onChangeCapebNumber}
                            value={capebNumber}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <View style={styles.checkboxContainer1}>
                            <Checkbox
                                style={styles.checkbox}
                                value={isAnnuaire}
                                onValueChange={setAnnuaire}
                            />
                            <Text style={styles.labelCheckbox}>Apparaitre dans notre annuaire professionnel ?</Text>
                        </View>
                        <TextInput
                            style={[isAnnuaire ? styles.inputTextArea : styles.inputTextAreNone, styles.inputOK ]}
                            multiline
                            numberOfLines={5}
                            placeholder="Décrivez vos compétences, vos spécialités ou encore vos zones d'intervention"
                            onChangeText={onChangeDescription}
                            value={description}
                            textAlignVertical={'top'}
                            placeholderTextColor={'#000'}
                            autoCorrect={false}
                            returnKeyType='none'
                            autoCapitalize='none'
                            blurOnSubmit={false}
                            keyboardType="default"
                            onSubmitEditing={()=>{Keyboard.dismiss()}}
                        />
                        <TouchableOpacity
                            onPress={saveAccount}
                            disabled={!checkFormData()}
                            style={checkFormData() ? styles.roundButton2 : styles.roundButton2No}>
                            <Text style={styles.textBold}>MODIFIER</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={deleteAccount}
                            style={styles.delete}>
                            <Text style={styles.textBold}>SUPPRIMER MON COMPTE</Text>
                        </TouchableOpacity>
                    </View>
                    </ScrollView>
                )}
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    hideError: {
        display: 'none'
    },
    showError: {
        color: '#df5952',
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#df5952'
    },
    image: {
        flex: 1,
        alignItems: 'center'
    },
    back: {
        flex: 1,
        position: 'absolute',
        left: 1,
        top: 72
    },
    logo: {
        width: 200,
        height: 70,
        margin: 70,
    },
    roundButton2: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#df5952',
        fontFamily: "GothamBold",
    },
    roundButton2No: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#CECECE',
        fontFamily: "GothamBold",
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    input: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    textBottomStep: {
        marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        bottom: 10,
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    placeholderT: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        color: '#df5952',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    placeholder: {
        fontSize: 20,
        color: '#686868',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    selected: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    select: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    containerMultiple: {
        marginTop: 5,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        height: 1
    },
    containerListJob: {
        marginTop: 80,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    selectMultiple2: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000'
    },
    selectMultiple2Dp: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000',
    },
    selectMultiple2DpSelectd: {
        height: 180,
    },
    selectM: {
        margin: 12,
        marginTop: 5,
        borderBottomWidth: 1,
        padding: 0,
        paddingLeft: 8,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
        color: '#000'
    },
    inputBlack: {
        color: '#000'
    },
    inputBlack2: {
        color: '#7f7f7f'
    },
    delete: {
        marginTop: 15,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        marginBottom: 20,
        backgroundColor: '#df5952',
        fontFamily: "GothamBold",
    },
    checkboxContainer1: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 20,
        width: 360,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 10,
        width: 360,
    },
    checkbox: {
        marginTop: 6,
    },
    labelCheckbox: {
        margin: 8,
        fontFamily: "Gotham",
        color: '#000'
    },
    inputTextArea: {
        fontFamily: "Gotham",
        margin: 12,
        padding: 14,
        height: 150,
        width: 360,
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 150,
        alignSelf: 'center',
        borderWidth: 1,
        borderColor: '#000'
    },
    inputTextAreNone: {
        fontFamily: "Gotham",
        margin: 0,
        padding: 0,
        width: '0%',
        height: 0,
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 0,
        alignSelf: 'center',
    },
    inputC: {
        margin: 12,
        marginTop: -16,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        fontFamily: "Gotham",
    },
    inputTextAreNoneC: {
        fontFamily: "Gotham",
        margin: 0,
        padding: 0,
        width: '0%',
        height: 0,
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 0,
        alignSelf: 'center',
    },
    credits: {
        paddingTop: 10,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: -5,
        fontFamily: "GothamBold",
        fontWeight: 'bold',
        margin: 12,
        width: 360,
        fontSize: 18
    }
});

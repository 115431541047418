import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    Animated,
    ActivityIndicator,
    Linking,
    Image,
    Dimensions,
    Platform,
    ScrollView
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import FlashMessage, {showMessage} from "react-native-flash-message";
import axios from "axios";
import Api from "../constants/Api";
import WebView from "react-native-webview";
import Helper from "../components/Helper";

export default function ActuDetailScreen({route, navigation}: RootStackScreenProps<'ActuDetail'>) {
    const [loading, setLoading] = React.useState(false);
    const [url, setUrl] = React.useState('');
    const [actu, onChangeActu] = React.useState({
        "description": "",
        "thumbnail": "",
        "title": "",
        "uuid": "",
        "content": "",
        "url": ""
    });
    const webviewElement = []
    // @ts-ignore
    const {uuid} = route.params;
    const {back} = route.params;

    const regex = /(<([^>]+)>)/ig;

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const actusFct = async () => {
        setLoading(true);
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/actualite/${uuid}`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeActu(response.data.data)
                //setUrl(response.data.data.file)
                setTimeout(function() { setLoading(false);}, 200);
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    function backTo() {
        if(back && back.length > 0) {
            return back
        } else {
            return 'Logged'
        }

    }

    const html = '<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"><link href="https://fonts.cdnfonts.com/css/gotham" type="text/css" media="screen">' +
        '<style>html { margin: 0; padding: 0} a {color: #000; font-weight: bold; font-family: "Gotham", sans-serif; } p { font-family: "Gotham", sans-serif; font-size: 16px; color: #000; }</style></head><body>'+actu.content+'</body></html>';

    if(Platform.OS === "web" ) {
        webviewElement.push(<iframe src={actu.url} height={'100%'} width={'100%'} frameBorder={0} />)
    } else {
        webviewElement.push( <WebView style={styles.webview}
                                                             automaticallyAdjustContentInsets={true}
                                                             onShouldStartLoadWithRequest={event => {
                                                                 if (event.url.slice(0,4) === 'http') {
                                                                     Linking.openURL(event.url)
                                                                     return false
                                                                 }
                                                                 return true
                                                             }}
                                                             source={{ html: html }}
        />)
    }

    useEffect(() => {
        isConnected().then()
        actusFct().then()
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText={actu && actu.title ? actu.title : ''} navigation={navigation} backTo={backTo()}
                            titleTextTop="" />
                {loading && actu && actu.file ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <View style={styles.insideContainer}>
                        {actu.thumbnail != '' ? (
                            <Image style={styles.imageStyle}  source={{uri:actu.thumbnail}}/>
                        ) : (
                            <Text> </Text>
                        )}
                        <Text style={styles.description}>{actu.description.replace(regex, '')}</Text>
                        {webviewElement}
                    </View>
                )}
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 0,
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    item: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#d0d0d0',
        flex: 1,
        flexDirection: 'row'
    },
    imageStyle: {
        width: '100%',
        height:230,
        resizeMode: "cover"
    },
    webview: {
        padding: 5,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height
    },
    description: {
        width: '100%',
        padding: 8,
        marginTop: 15,
        fontSize: 16,
        fontFamily: "GothamBold",
    },
    renderView: {
        width: '100%',
        paddingLeft: 8
    }
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
    SafeAreaView, Platform, Alert, ScrollView
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import SelectPicker from "react-native-form-select-picker";
import axios from "axios";
import Api from "../constants/Api";
import qs from "querystring";
import Checkbox from 'react-native-checkbox';
import FlashMessage, {showMessage} from "react-native-flash-message";
import MultiSelect from 'react-native-multiple-select';
import Helper from "../components/Helper";

export default function InviteUserScreen({route, navigation}: RootStackScreenProps<'InviteUser'>) {
    const [email, onChangeEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const checkFormData = () => email && email.length > 6 && validate(email);
    const validate = (email: any) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase())
    }
    // @ts-ignore
    const {uuid} = route.params;
    const {back} = route.params;

    const inviteUser = async () => {
        setLoading(true)
        const payload = {
            email,
            uuid
        };
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.post(`${Api.baseUrl}/api/invite-member`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                setLoading(false)
                showMessage({
                    message: "Succès !",
                    description: "L'utilisateur à été invité.",
                    type: "success",
                    duration: 3000
                });
                // @ts-ignore
                return navigation.push('ProjectDetail', {uuid: uuid});
            }
        ).catch(
            function () {
                setLoading(false)
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    function backTo() {
        return 'ProjectList'
    }

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    useEffect(() => {
        isConnected().then()
    }, []);

    // @ts-ignore
    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="INVITATION" titleTextTop="" navigation={navigation}  backTo={backTo()} />
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <View style={styles.insideContainer}>
                        <View style={styles.textBlock}>
                            <Text>Saisir une adresse email pour envoyer une invitation à rejoindre le projet</Text>
                        </View>
                        <TextInput
                            style={[email ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Adresse email *"
                            onChangeText={onChangeEmail}
                            value={email}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <Text style={[validate(email) || email.length === 0 ? styles.hideError : styles.showError]}>Adresse email invalide</Text>
                        <TouchableOpacity
                            onPress={inviteUser}
                            disabled={!checkFormData()}
                            style={checkFormData() ? styles.roundButton2 : styles.roundButton2No}>
                            <Text style={styles.textBold}>INVITER</Text>
                        </TouchableOpacity>
                    </View>
                )}
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    hideError: {
        display: 'none'
    },
    showError: {
        marginTop: 10,
        color: '#df5952',
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    image: {
        flex: 1,
        alignItems: 'center'
    },
    back: {
        flex: 1,
        position: 'absolute',
        left: 1,
        top: 72
    },
    textB: {
        fontSize: 16,
        padding: 0,
        marginTop: -4,
        fontFamily: "Gotham",
    },
    logo: {
        width: 200,
        height: 70,
        margin: 70,
    },
    roundButton2: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#C6362D',
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40
    },
    roundButton2No: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#CECECE',
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40
    },
    textBlock: {
        margin: 5,
        padding: 15,
        width: '100%',
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    input: {
        margin: 20,
        padding: 10,
        width: '95%',
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    textBottomStep: {
        marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        bottom: 10,
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    placeholderT: {
        margin: 20,
        padding: 10,
        width: '95%',
        height: 55,
        fontSize: 20,
        color: '#C6362D',
        fontWeight: 'normal',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    placeholder: {
        fontSize: 20,
        color: '#686868',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    selected: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    select: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    containerMultiple: {
        marginTop: 5,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        height: 1
    },
    containerListJob: {
        marginTop: 80,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    selectMultiple2: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000'
    },
    selectMultiple2Dp: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000',
    },
    selectMultiple2DpSelectd: {
        height: 180,
    },
    selectM: {
        margin: 12,
        marginTop: 5,
        borderBottomWidth: 1,
        padding: 0,
        paddingLeft: 8,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
        color: '#000'
    },
    inputBlack: {
        color: '#000'
    },
    delete: {
        marginTop: 15,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        marginBottom: 20,
        backgroundColor: '#C6362D',
        fontFamily: "GothamBold",
    },
    checkboxContainer1: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 20,
        width: 360,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 10,
        width: 360,
    },
    checkbox: {

    },
    labelCheckbox: {
        margin: 8,
        fontFamily: "Gotham",
        color: '#000'
    },
});

import {Image, StyleSheet, Text, View, TouchableOpacity, ScrollView, TextInput} from 'react-native';
import React from "react";
import {Platform} from 'react-native';
export type menuProps = { isOpen?: any, navigation?:any, onChangeIsOpen?:any };

class Header extends React.Component<menuProps> {
    render () {
        return (
            <View style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.container : styles.containerweb}>
                <View style={styles.width20}>
                    <TouchableOpacity onPress={this.props.onChangeIsOpen}>
                        <Image
                            style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.burger : styles.burgerweb}
                            source={require('./../assets/images/burger.png')}
                        />
                    </TouchableOpacity>
                </View>
                <View style={styles.width60} >
                    <Image
                        style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logo : styles.logoweb}
                        source={require('./../assets/images/logo_capeb.png')}
                    />
                </View>
                <View style={styles.width20}>

                </View>
            </View>
        );
    }
}

export default Header;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        height: 120,
        backgroundColor: '#000',
    },
    containerweb: {
        flexDirection: 'row',
        height: 80,
        backgroundColor: '#000',
    },
    logo: {
        width: 160,
        height: 60,
        marginLeft: -10,
        marginTop: 50,
    },
    burger: {
        width: 40,
        height: 40,
        marginTop: 56,
    },
    logoweb: {
        width: 160,
        height: 60,
        marginLeft: -10,
        marginTop: 10,
    },
    burgerweb: {
        width: 40,
        height: 40,
        marginTop: 20,
    },
    search: {
        width: 25,
        height: 25,
        marginTop: 8,
        marginLeft: 8
    },
    searchContainer: {
        width: 44,
        height: 44,
        borderRadius: 100,
        backgroundColor: '#F4F4F4',
        marginTop: 58,
        zIndex: 0
    },
    width20: {
        width: '20%',
        alignItems: 'center'
    },
    width60: {
        width: '60%',
        alignItems: 'center'
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    input: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 320,
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    placeholderT: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 320,
        height: 55,
        fontSize: 20,
        color: '#df5952',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    top: {
        marginTop:-10
    },
});

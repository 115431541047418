import {StatusBar} from 'expo-status-bar';
import React, {Component, useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    Animated,
    ScrollView,
    Image,
    TouchableOpacity, ActivityIndicator, Platform, ImageBackground
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Menu from '../screens/BurgerMenu';
import Header from "../components/HeaderLogged";
// import * as Permissions from "expo-permissions";
import * as Notifications from "expo-notifications";
import SideMenu from 'react-native-side-menu';
import axios from "axios";
import Api from "../constants/Api";
import qs from "querystring";
import {useIsFocused} from '@react-navigation/native';
import Helper from "../components/Helper";

export default function LoggedScreen({navigation}: RootStackScreenProps<'Logged'>) {
    const [isOpen, onChangeIsOpen] = React.useState(false)
    const [actualite, onChangeActualite] = React.useState('');
    const [dashboard, onChangeDashboard] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const listActualite = [];
    const isFocused = useIsFocused();

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        //await AsyncStorage.setItem('access_token', '')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const ActualiteFct = async (loader: boolean) => {
        if (loader) {
            setLoading(true);
        }
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/actualites`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeActualite(response.data.data)
                setLoading(false);
            }
        ).catch(
            async function () {
                setLoading(false);
                await AsyncStorage.setItem('access_token', '')
                navigation.navigate('Home')
            }
        )
    }

    const hPDashboard = async (loader: boolean) => {
        if (loader) {
            setLoading(true);
        }
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/dashboard`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeDashboard(response.data.data)
                setLoading(false);
            }
        ).catch(
            async function () {
                setLoading(false);
                await AsyncStorage.setItem('access_token', '')
                navigation.navigate('Home')
            }
        )
    }

    const registerForPushNotifications = async () => {
        try {
            const permission = await Permissions.askAsync(Permissions.NOTIFICATIONS);
            if (!permission.granted) return;
            const token = (await Notifications.getExpoPushTokenAsync()).data;
            const access_token = await AsyncStorage.getItem('access_token')
            const payload = {
                token: token
            };
            await axios.post(`${Api.baseUrl}/api/user/token`, qs.stringify(payload), {
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
        } catch (error) {
            console.log('Error getting a token', error);
        }
    }

    const updateMenuState = async (isOpenValue: boolean) => {
        onChangeIsOpen(isOpenValue)
    }

    useEffect(() => {
        isConnected().then()
        registerForPushNotifications().then()
        ActualiteFct(true).then()
        hPDashboard(true).then()
        document.body.style.overflowX = "hidden";
    }, [isFocused]);

    const menu = <Menu isOpen={isOpen} navigation={navigation} onChangeIsOpen={onChangeIsOpen}/>;

    for (let i = 0; i < actualite.length; i++) {
        const image = {uri: actualite[i].thumbnail};
        listActualite.push(
            <View style={styles.item}>
                <TouchableOpacity style={styles.discover}
                                  onPress={() => navigation.push('ActuDetail', {uuid: actualite[i].uuid})}>
                    <ImageBackground source={image} resizeMode="cover" imageStyle={{borderRadius: 10}}
                                     style={styles.image}>
                        <View style={styles.itemInside}>
                            <Text style={styles.title}>{actualite[i].title}</Text>
                        </View>
                    </ImageBackground>
                </TouchableOpacity>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <SideMenu
                animationFunction={(prop, value) =>
                    Animated.spring(prop, {
                        toValue: value,
                        friction: 8,
                        useNativeDriver: true,
                    })
                }
                autoClosing={true}
                menu={menu}
                isOpen={isOpen}
                onChange={(isOpen) => updateMenuState(isOpen)}>
                <View style={styles.flex}>
                    <Header isOpen={isOpen} onChangeIsOpen={onChangeIsOpen} navigation={navigation}/>
                    {loading ? (
                        <View style={[styles.containerLoader, styles.horizontal]}>
                            <ActivityIndicator size="large" color="#df5952"/>
                        </View>
                    ) : (
                        <ScrollView style={styles.inside}>
                            <View>
                                <Text style={styles.titleTop}>Bienvenue {dashboard.firstname} !</Text>
                            </View>
                            <View style={styles.blocContainerGroup}>
                                <TouchableOpacity style={[styles.bloc, styles.blue]} onPress={() => navigation.push('ProjectList')}>
                                    <Image
                                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logosmall : styles.logo]}
                                        source={require('./../assets/images/logo-1.png')}
                                    />
                                    <Text style={styles.textInfo}>{dashboard.projects} dossier(s) en cours et terminé(s)</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.bloc, styles.orange]}  onPress={() => navigation.push('AnnuaireList')}>
                                    <Image
                                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logosmall : styles.logo]}
                                        source={require('./../assets/images/logo-2.png')}
                                    />
                                    <Text style={styles.textInfo}>{dashboard.registry} artisans dans l’annuaire</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.blocContainerGroup}>
                                <TouchableOpacity style={[styles.bloc, styles.violet]} onPress={() => navigation.push('ProjectList', {signed: '1'})}>
                                    <Image
                                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logosmall : styles.logo]}
                                        source={require('./../assets/images/logo-3.png')}
                                    />
                                    <Text style={styles.textInfo}>{dashboard.contract} contrat(s) signé(s)</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.bloc, styles.vert]} onPress={() => navigation.push('Contact')}>
                                    <Image
                                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logosmall : styles.logo]}
                                        source={require('./../assets/images/logo-4.png')}
                                    />
                                    <Text style={styles.textInfo}>nous contacter</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.blocContainerGroup}>
                                <TouchableOpacity style={[styles.bloc2, styles.red]} onPress={() => navigation.push('BourseList')}>
                                    <Image
                                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logosmall2 : styles.logo2]}
                                        source={require('./../assets/images/bourse.png')}
                                    />
                                    <Text style={styles.textInfo}>Déposer ou trouver une proposition de travaux</Text>
                                </TouchableOpacity>
                            </View>
                            <View>
                                <Text style={styles.titleActualite}>Actualités</Text>
                            </View>
                            <View>
                                {listActualite}
                            </View>
                        </ScrollView>)}
                </View>
                <Helper navigation={navigation} />
                <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'}/>
            </SideMenu>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    inside: {
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
        height: '100%',
        flex: 2,
        width: '100%'
    },
    flex: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#EFEFEF',
        marginBottom: -60
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    alignCenter: {
        alignContent: 'center',
        justifyContent: 'center'
    },
    like: {
        backgroundColor: '#fff',
        width: '30%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    download: {
        backgroundColor: '#484848',
        width: '25%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    discover: {
        height: 120,
        borderRadius: 10,
        backgroundColor: '#484848'
    },
    image: {
        flex: 1,
        alignItems: 'center',
        borderRadius: 10,
    },
    textDiscover: {
        color: '#FFF',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        alignContent: 'center',
        justifyContent: 'center',
        fontSize: 18
    },
    item: {
        margin: 10,
        marginTop: 5,
        marginBottom: 10,
        flex: 1,
        backgroundColor: '#fff',
        flexDirection: 'column',
        borderRadius: 10
    },
    itemInside: {
        padding: 10,
        bottom: 10,
        left: 10,
        position: 'absolute'
    },
    imageStyle: {
        width: 100,
        height: 100,
        resizeMode: "cover"
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontFamily: "GothamBold",
        color: 'white',
        bottom: 0
    },
    description: {
        marginTop: 10,
        fontSize: 18,
        fontFamily: "Gotham",
    },
    webview: {
        height: 40
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    roundButton2: {
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#df5952',
        bottom: 30,
        fontFamily: "GothamBold",
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20
    },
    insideElement: {
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    overlay: {
        backgroundColor: '#484848',
    },
    titleActualite: {
        padding: 10,
        textTransform: "uppercase",
        fontSize: 26,
        fontWeight: 'bold',
        paddingLeft: 15,
    },
    titleTop: {
        padding: 10,
        paddingTop: 20,
        paddingLeft: 15,
        textTransform: "uppercase",
        fontSize: 26,
        fontWeight: 'bold',
        paddingBottom: 20
    },
    bloc: {
        borderRadius: 10,
        height: 170,
        width: '45%',
        marginLeft: '3%',
        marginBottom: 15,
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
    },
    bloc2: {
        borderRadius: 10,
        height: 170,
        width: '93%',
        marginLeft: '3%',
        marginBottom: 15,
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
    },
    blue: {
        backgroundColor: '#5899D4',
    },
    orange: {
        backgroundColor: '#DC9124',
    },
    violet: {
        backgroundColor: '#54287F',
    },
    vert: {
        backgroundColor: '#5EAB95',
    },
    red: {
        backgroundColor: '#C6362D',
    },
    blocContainerGroup: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        alignSelf: 'center',
    },
    logo: {
        width: 80,
        height: 80,
        marginTop: 20,
        alignItems: 'center',
    },
    logo2: {
        width: 50,
        height: 80,
        marginTop: 20,
        alignItems: 'center',
    },
    logosmall2: {
        width: 50,
        height: 80,
        marginTop: 20,
        alignItems: 'center',
    },
    logosmall: {
        width: 80,
        height: 80,
        marginTop: 20,
        alignItems: 'center',
    },
    textInfo: {
        color: 'white',
        textTransform: "uppercase",
        padding: 10,
        fontWeight: 'bold',
        alignItems: 'center',
        textAlign: 'center'
    }
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    Image,
    ScrollView,
    TouchableOpacity,
    ActivityIndicator, Platform, TextInput, Linking
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import axios from "axios";
import Api from "../constants/Api";
import FlashMessage, {showMessage} from "react-native-flash-message";
import qs from "querystring";
import {red} from "@material-ui/core/colors";
import Icon from "react-native-vector-icons/FontAwesome";
import Helper from "../components/Helper";

export default function AnnuaireListScreen({navigation}: RootStackScreenProps<'AnnuaireList'>) {
    const [registry, onChangeRegistry] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [search, onChangeSearch] = React.useState('');
    const [distance, onChangeDistance] = React.useState('');
    const [location, onChangeLocation] = React.useState('');
    const listRegistry = [];
    let noContent = <View style={styles.itemEmpty}/>;

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const onPressMobileNumberClick = (number) => {
        let phoneNumber = '';
        if (Platform.OS === 'android') {
            phoneNumber = `tel:${number}`;
        } else if (Platform.OS === 'ios') {
            phoneNumber = `telprompt:${number}`;
        } else {
            phoneNumber = `tel:${number}`;
        }

        Linking.openURL(phoneNumber);
    }

    const updateSearch = async (loader: boolean, ev: string) => {
        onChangeSearch(ev)
        await dataFct(false, ev, location, distance)
    }

    const updateLocation = async (loader: boolean, ev: string) => {
        onChangeLocation(ev)
        await dataFct(false, search, ev, distance)
    }

    const updateDistance = async (loader: boolean, ev: string) => {
        onChangeDistance(ev)
        await dataFct(false, search, location, ev)
    }

    const dataFct = async (loader: boolean, search: string, location: string, distance: string) => {
        if (loader) {
            setLoading(true);
        }
        const access_token = await AsyncStorage.getItem('access_token')
        let payload = {}
        payload = {search, location, distance}
        await axios.post(`${Api.baseUrl}/api/registry`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeRegistry(response.data.registry)
                setTimeout(function () {
                    setLoading(false);
                }, 50);
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    useEffect(() => {
        isConnected().then()
        dataFct(true, '').then()
    }, []);

    for (let i = 0; i < registry.length; i++) {
        listRegistry.push(
            <View style={styles.item}>
                <Text style={styles.category}>{registry[i].firstname} {registry[i].lastname}</Text>
                <Text style={styles.category2}>Société {registry[i].company}</Text>
                <Text style={styles.category3}>{registry[i].job} dans le département {registry[i].dpt}</Text>
                <Text style={styles.category4}>{registry[i].description}</Text>
                <View>
                    <TouchableOpacity onPress={() => {
                        onPressMobileNumberClick(registry[i].phone)
                    }} style={styles.flex2}>
                        <Icon name="phone" size={15} color="#C6362D" style={styles.icon}/><Text
                        style={styles.textPhone}> {registry[i].phone}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        Linking.openURL('mailto:' + registry[i].email)
                    }} style={styles.flex3}>
                        <Icon name="envelope" size={15} color="#C6362D" style={styles.icon}/><Text
                        style={styles.textPhone}> {registry[i].email}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }

    if (registry.length === 0) {
        noContent = <View style={styles.itemEmpty}>
            <Text style={styles.textEmpty}>Aucun résultat, affinez votre recherche ...</Text>
        </View>
    }

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="ANNUAIRE" titleTextTop="" navigation={navigation}/>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <ScrollView scrollsToTop={true} style={styles.insideContainer}>
                        <TextInput
                            style={[location ? styles.input : styles.placeholderT, location.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder="Ville ? Code postal ?"
                            value={location}
                            placeholderTextColor={'#484848'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={false}
                            onChangeText={(ev) => updateLocation(false, ev)}
                        />
                        <TextInput
                            style={[search ? styles.input : styles.placeholderT, search.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder="Métier(s) ? Spécialité(s) ?"
                            value={search}
                            placeholderTextColor={'#484848'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={false}
                            onChangeText={(ev) => updateSearch(false, ev)}
                        />
                        <TextInput
                            style={[distance ? styles.input : styles.placeholderT, distance.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder="Rayon en kilomètre(s) ?"
                            value={distance}
                            placeholderTextColor={'#484848'}
                            keyboardType='numeric'
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={false}
                            onChangeText={(ev: string) => updateDistance(false, ev)}
                            maxLength={4}
                        />
                        {listRegistry}
                        {noContent}
                    </ScrollView>
                )}
            </View>
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    imageStyle: {
        width: 100,
        height: 120,
        resizeMode: "cover"
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    flex2: {
        marginTop: 10,
        flex: 1,
        flexDirection: 'row',
        width: '100%',
    },
    flex3: {
        marginTop: 15,
        flex: 1,
        flexDirection: 'row',
        width: '100%',
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    textEmpty: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        fontSize: 16
    },
    insideContainer: {
        flex: 1,
        backgroundColor: '#FFF',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    item: {
        padding: 10,
        paddingBottom: 15,
        width: '95%',
        borderRadius: 5,
        backgroundColor: '#222220',
        margin: 5,
        alignSelf: 'center',
    },
    textPhone: {
        fontSize: 14,
        fontFamily: "GothamBold",
        width: '100%',
        color: 'white'
    },
    itemEmpty: {
        padding: 10,
        borderBottomWidth: 0,
        borderBottomColor: '#d0d0d0',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    category: {
        fontSize: 20,
        fontFamily: "GothamBold",
        marginTop: 10,
        color: 'white'
    },
    category2: {
        fontSize: 17,
        fontFamily: "Gotham",
        marginTop: 10,
        color: 'white'
    },
    category3: {
        fontSize: 14,
        fontFamily: "Gotham",
        marginTop: 10,
        color: 'white'
    },
    category4: {
        fontSize: 14,
        fontFamily: "Gotham",
        marginTop: 10,
        marginBottom: 10,
        color: 'white'
    },
    title: {
        fontSize: 18,
        fontFamily: "Gotham",
    },
    leftBlock: {
        paddingLeft: 10,
        flexShrink: 1
    },
    actionContainer: {
        marginTop: 20,
        marginBottom: 10,
        width: 45,
        height: 45,
        zIndex: 99999,
        left: 190,
    },
    actionBtn: {
        marginTop: 0,
        marginBottom: 10,
        width: 45,
        height: 45,
        zIndex: 9999999,
    },
    logo: {},
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    insideElement: {
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    roundButton2: {
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#484848',
        bottom: 30,
        left: 15,
        position: 'absolute',
        fontFamily: "GothamBold",
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    placeholder: {
        fontSize: 20,
        color: '#484848',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    input: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 2,
        padding: 10,
        width: '95%',
        alignSelf: 'center',
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    placeholderT: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 2,
        padding: 10,
        width: '95%',
        alignSelf: 'center',
        height: 55,
        fontSize: 20,
        color: '#484848',
        fontWeight: 'normal',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    inputBlack: {
        color: '#000',
    },
    row: {
        flexDirection: 'row'
    },
    width30: {
        width: '30%',
        flexDirection: 'row'
    },
    width70: {
        width: '70%',
        flexDirection: 'row'
    },
    icon: {
        width: 15
    }
});

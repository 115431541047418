import { StatusBar } from 'expo-status-bar';
import React from 'react';
import {StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity, Platform, Dimensions} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function HomeScreen ({ navigation }: RootStackScreenProps<'Home'>)  {
  const isConnected = async () => {
    const access_token = await AsyncStorage.getItem('access_token')
    if (access_token && access_token.length > 0) {
      navigation.navigate('Logged')
    }
  }
  isConnected().then( )
  const isSmallDevice = Dimensions.get('window').width < 768

  return (
      <View style={styles.container}>
        <ImageBackground source={require('./../assets/images/background.png')} resizeMode="cover" style={styles.image}>
          <View style={styles.header}>
            <Image
                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logosmall : styles.logo]}
                source={require('./../assets/images/logo_capeb.png')}
            />
          </View>
          <TouchableOpacity
              onPress={() => navigation.navigate('Login')}
              style={styles.roundButton1}>
            <Text style={styles.textBold}>CONNEXION</Text>
          </TouchableOpacity>
          <TouchableOpacity
              onPress={() => navigation.navigate('Register')}
              style={styles.roundButton2}>
            <Text style={styles.textBold}>CRÉER UN COMPTE</Text>
          </TouchableOpacity>
        </ImageBackground>
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  image: {
    flex: 1,
    alignItems: 'center',
  },
  roundButton1: {
    marginTop: 280,
    width: 360,
    height: 55,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#484848',
    bottom: 130,
    position: 'absolute',
    fontFamily: "GothamBold",
  },
  textBold: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 20
  },
  roundButton2: {
    width: 360,
    height: 55,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#df5952',
    bottom: 50,
    position: 'absolute',
    fontFamily: "GothamBold",
  },
  button: {
    width: 300,
    height: 105,
    margin: 45,
  },
  header : {
    backgroundColor: '#000',
    width: '100%',
    alignItems: 'center',
    height: 130,
    marginBottom: 30
  },
  logo: {
    width: 200,
    height: 70,
    marginTop: 25
  },
  logosmall: {
    width: 200,
    height: 70,
    marginTop: 45
  },
  back: {
    flex: 1,
    position: 'absolute',
    left: 1,
    top: '35%'
  },
  backsmall: {
    flex: 1,
    position: 'absolute',
    left: 1,
    top: '55%'
  },
});

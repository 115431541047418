import React from 'react';
import {
    ScrollView,
    Text,
    StyleSheet,
    Image,
    TouchableOpacity,
    useWindowDimensions,
    Dimensions,
    View
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

export type menuProps = { isOpen?: boolean, navigation?: any, onChangeIsOpen?: any };

function goToMenu(props: Readonly<menuProps> & Readonly<{ children?: React.ReactNode; }>, goTo: string) {
    props.onChangeIsOpen(false)
    props.navigation.navigate(goTo)
}

const logout = async (props: Readonly<menuProps> & Readonly<{ children?: React.ReactNode; }>) => {
    await AsyncStorage.setItem('access_token', '')
    const access_token = await AsyncStorage.getItem('access_token')
    if (!access_token) {
        props.navigation.navigate('Home')
    }
}

class BurgerMenu extends React.Component<menuProps> {
    render() {
        return (
            <ScrollView scrollsToTop={true} style={this.props.isOpen ? styles.menuDisplay : styles.menuHide}>
                <TouchableOpacity style={this.props.isOpen ? styles.containerItem : styles.hide} onPress={() => goToMenu(this.props, 'ProjectList')}>
                    <Text
                        style={styles.item}>
                        Mes dossiers
                    </Text>
                </TouchableOpacity>
                <View style={this.props.isOpen ? styles.separator : styles.hide}>
                </View>
                <TouchableOpacity style={this.props.isOpen ? styles.containerItem : styles.hide} onPress={() => goToMenu(this.props, 'AnnuaireList')}>
                    <Text
                        style={styles.item}>
                        Annuaire
                    </Text>
                </TouchableOpacity>
                <View style={this.props.isOpen ? styles.separator : styles.hide}>
                </View>
                <TouchableOpacity style={this.props.isOpen ? styles.containerItem : styles.hide} onPress={() => goToMenu(this.props, 'BourseList')}>
                    <Text
                        style={styles.item}>
                        Bourse de travaux
                    </Text>
                </TouchableOpacity>
                <View style={this.props.isOpen ? styles.separator : styles.hide}>
                </View>
                <TouchableOpacity style={this.props.isOpen ? styles.containerItem : styles.hide} onPress={() => goToMenu(this.props, 'Contact')}>
                    <Text
                        style={styles.item}>
                        Contact
                    </Text>
                </TouchableOpacity>
                <View style={this.props.isOpen ? styles.separator : styles.hide}>
                </View>
                <TouchableOpacity style={this.props.isOpen ? styles.containerItem : styles.hide} onPress={() => goToMenu(this.props, 'Profile')}>
                    <Text
                        style={styles.item}>
                        Mon compte
                    </Text>
                </TouchableOpacity>
                <View style={this.props.isOpen ? styles.separator : styles.hide}>
                </View>
                <TouchableOpacity style={this.props.isOpen ? styles.containerItem : styles.hide} onPress={() => logout(this.props)}>
                    <Text
                        style={styles.item}>
                        Déconnexion
                    </Text>
                </TouchableOpacity>
            </ScrollView>
        );
    }
}

const styles = StyleSheet.create({
    containerItem: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: '#000',
    },
    logos: {
        width: 40,
        height: 40,
        marginLeft: 20
    },
    menuHide: {
        marginTop: 0,
        flex: 1,
        width: Dimensions.get('window').width,
        backgroundColor: '#000'
    },
    hide: {
        display: 'none'
    },
    menuDisplay: {
        flex: 1,
        padding: 0,
        paddingTop: 60,
        backgroundColor: '#000',
        width: Dimensions.get('window').width,
    },
    item: {
        fontSize: 20,
        paddingTop: 12,
        marginLeft: 14,
        color: '#fff',
        marginBottom: 30,
        fontFamily: "GothamBold",
        textTransform: "uppercase"
    },
    page: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#777'
    },
    pageContent: {
        flex: 1,
        alignItems: 'center',
        top: 200,
    },
    separator: {
        width: '100%',
        marginBottom: 22,
        height: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

export default BurgerMenu;

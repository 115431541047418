import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './hooks/useCachedResources';
import Navigation from './navigation';
import { useFonts } from 'expo-font';
import FlashMessage from "react-native-flash-message";
import React, {useEffect} from "react";
import {StyleSheet, Text, TouchableOpacity} from "react-native";
import {RootStackScreenProps} from "./types";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function App({navigation}: RootStackScreenProps<'Form'>) {
    const isLoadingComplete = useCachedResources();
    let [fontsLoaded] = useFonts({
        'GothamBold': require('./assets/fonts/Gotham-Bold.otf'),
        'Gotham': require('./assets/fonts/Gotham-Light.otf'),
    });

    useEffect(() => {
        document.body.style.overflowX = "hidden";
        document.documentElement.setAttribute("lang", 'fr');
    });

  if (!isLoadingComplete) {
    return null;
  } else  {
    // @ts-ignore
      return (
      <SafeAreaProvider>
        <Navigation />
        <StatusBar style='light' />
        <FlashMessage position="top"/>
      </SafeAreaProvider>
    );
  }
}

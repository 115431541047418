import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    TouchableOpacity,
    ActivityIndicator, Platform, TextInput, Image
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import axios from "axios";
import Api from "../constants/Api";
import FlashMessage, {showMessage} from "react-native-flash-message";
import qs from "querystring";
import {useIsFocused} from '@react-navigation/native';
import Helper from "../components/Helper";

export default function ProjectListScreen({route, navigation}: RootStackScreenProps<'ProjectList'>) {
    const [projects, onChangeProjects] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [search, onChangeSearch] = React.useState('');
    const listProject = [];
    let noContent = <View style={styles.itemEmpty}/>;
    const isFocused = useIsFocused();


    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const updateSearch = async (loader: boolean, ev: string) => {
        onChangeSearch(ev)
        await dataFct(false, ev)
    }

    const dataFct = async (loader: boolean, search: string) => {
        if (loader) {
            setLoading(true);
        }
        const access_token = await AsyncStorage.getItem('access_token')
        let payload = {}
        if (search && search.length > 0) {
            payload = {search}
        }
        await axios.post(`${Api.baseUrl}/api/projects`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeProjects(response.data.projects)
                setTimeout(function () {
                    setLoading(false);
                }, 50);
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    function backTo() {
        return 'Logged'
    }

    useEffect(() => {
        isConnected().then()
        dataFct(true, '').then()
    }, [isFocused]);

    for (let i = 0; i < projects.length; i++) {
        if(typeof route.params !== 'undefined') {
            if(projects[i].signed === true) {
                listProject.push(
                    <TouchableOpacity
                        onPress={() => navigation.push('ProjectDetail', {uuid: projects[i].uuid})}>
                        <View style={styles.item}>
                            <TouchableOpacity style={styles.icon2}
                                              onPress={() => navigation.push('ProjectDetail', {uuid: projects[i].uuid})}><Image
                                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                                source={require('./../assets/images/logo-edit.png')}
                            /></TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.push('ProjectDetail', {uuid: projects[i].uuid})}>
                                <View style={[styles.flex2]}><Text
                                    style={styles.category}>{projects[i].name}</Text></View>
                                <View style={[styles.flex2, styles.borderBottom]}><Text
                                    style={styles.category2}>{projects[i].author}</Text></View>
                                <View style={styles.flex2}>
                                    <Image
                                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon : styles.iconWeb]}
                                        source={require('./../assets/images/logo-agenda.png')}
                                    />
                                    <Text style={styles.font20}>{projects[i].date}</Text></View>
                                <View style={styles.flex2}><Image
                                    style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon : styles.iconWeb]}
                                    source={require('./../assets/images/logo-contract.png')}
                                /><Text style={styles.font20}>{projects[i].contracts} document(s)</Text></View>
                                <View style={styles.flex2}><Image
                                    style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon : styles.iconWeb]}
                                    source={require('./../assets/images/logo-people.png')}
                                /><Text style={styles.font20}> {projects[i].users} membre(s)</Text></View>
                            </TouchableOpacity>
                        </View>
                    </TouchableOpacity>
                );
            }
        } else {
            listProject.push(
                <TouchableOpacity
                    onPress={() => navigation.push('ProjectDetail', {uuid: projects[i].uuid})}>
                    <View style={styles.item}>
                        <TouchableOpacity style={styles.icon2}
                                          onPress={() => navigation.push('ProjectDetail', {uuid: projects[i].uuid})}><Image
                            style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                            source={require('./../assets/images/logo-edit.png')}
                        /></TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.push('ProjectDetail', {uuid: projects[i].uuid})}>
                            <View style={[styles.flex2]}><Text
                                style={styles.category}>{projects[i].name}</Text></View>
                            <View style={[styles.flex2, styles.borderBottom]}><Text
                                style={styles.category2}>{projects[i].author}</Text></View>
                            <View style={styles.flex2}>
                                <Image
                                    style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon : styles.iconWeb]}
                                    source={require('./../assets/images/logo-agenda.png')}
                                />
                                <Text style={styles.font20}>{projects[i].date}</Text></View>
                            <View style={styles.flex2}><Image
                                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon : styles.iconWeb]}
                                source={require('./../assets/images/logo-contract.png')}
                            /><Text style={styles.font20}>{projects[i].contracts} document(s)</Text></View>
                            <View style={styles.flex2}><Image
                                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon : styles.iconWeb]}
                                source={require('./../assets/images/logo-people.png')}
                            /><Text style={styles.font20}> {projects[i].users} membre(s)</Text></View>
                        </TouchableOpacity>
                    </View>
                </TouchableOpacity>
            );
        }
    }

    if (projects.length === 0) {
        noContent = <View style={styles.itemEmpty}>
            <Text style={styles.textEmpty}>Aucun projet</Text>
        </View>
    }

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="MES DOSSIERS" titleTextTop="" navigation={navigation} backTo={backTo()}/>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <ScrollView scrollsToTop={true} style={styles.insideContainer}>
                        <TextInput
                            style={[search ? styles.input : styles.placeholderT, search.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder="Rechercher ..."
                            value={search}
                            placeholderTextColor={'#484848'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={false}
                            onChangeText={(ev) => updateSearch(false, ev)}
                        />
                        {listProject}
                        {noContent}
                    </ScrollView>
                )}
            </View>
            <View style={styles.insideElement}>
                <TouchableOpacity
                    onPress={() => navigation.navigate('Project')}
                    style={styles.roundButton2}>
                    <Image
                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                        source={require('./../assets/images/logo-create.png')}
                    /><Text style={styles.textBold}> CRÉER UN
                    DOSSIER</Text>
                </TouchableOpacity>
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    imageStyle: {
        width: 100,
        height: 120,
        resizeMode: "cover"
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    flex2: {
        flexDirection: 'row',
        padding: 2,
        marginBottom: 6
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    textEmpty: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        fontSize: 16
    },
    insideContainer: {
        backgroundColor: '#FFF',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    item: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#d0d0d0',
        flexDirection: 'row',
        width: '95%',
        borderRadius: 5,
        backgroundColor: '#222220',
        margin: 5,
        alignSelf: 'center',
    },
    itemEmpty: {
        padding: 10,
        borderBottomWidth: 0,
        borderBottomColor: '#d0d0d0',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    category: {
        fontSize: 18,
        fontFamily: "GothamBold",
        paddingTop: 0,
        paddingBottom: 0,
        color: 'white',
        textTransform: 'uppercase',
    },
    category2: {
        fontSize: 14,
        fontFamily: "GothamBold",
        paddingBottom: 5,
        color: '#C0C0C0',
    },
    title: {
        fontSize: 20,
        fontFamily: "Gotham",
    },
    leftBlock: {
        paddingLeft: 10,
        flexShrink: 1
    },
    actionContainer: {
        marginTop: 20,
        marginBottom: 10,
        width: 45,
        height: 45,
        zIndex: 99999,
        left: 190,
    },
    actionBtn: {
        marginTop: 0,
        marginBottom: 10,
        width: 45,
        height: 45,
        zIndex: 9999999,
    },
    logo: {},
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    insideElement: {
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    roundButton2: {
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        padding: 10,
        backgroundColor: '#C6362D',
        borderRadius: 5,
        bottom: 30,
        fontFamily: "GothamBold",
        flexDirection: 'row',
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    placeholder: {
        fontSize: 20,
        color: '#484848',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    input: {
        margin: 20,
        padding: 10,
        width: '95%',
        alignSelf: 'center',
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    placeholderT: {
        margin: 20,
        padding: 10,
        width: '95%',
        alignSelf: 'center',
        height: 55,
        fontSize: 20,
        color: '#484848',
        fontWeight: 'normal',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    inputBlack: {
        color: '#000',
    },
    icon: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWeb: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon3: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWeb3: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon2: {
        top: 10,
        right: 10,
        position: 'absolute',
    },
    font20: {
        fontSize: 18,
        color: 'white',
        marginLeft: 10
    },
    borderBottom: {
        borderBottomColor: 'white',
        borderBottomWidth: 1
    }
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
    SafeAreaView, Platform, Alert, ScrollView, Keyboard
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import SelectPicker from "react-native-form-select-picker";
import axios from "axios";
import Api from "../constants/Api";
import qs from "querystring";
import Checkbox from 'react-native-checkbox';
import FlashMessage, {showMessage} from "react-native-flash-message";
import MultiSelect from 'react-native-multiple-select';
import {useIsFocused} from '@react-navigation/native';
import Helper from "../components/Helper";
export default function ProjectEditScreen({route, navigation}: RootStackScreenProps<'ProjectDetail'>) {
    const [name, onChangeText] = React.useState('');
    const [description, onChangeDescription] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const isFocused = useIsFocused();
    const checkFormData = () => name && name.length > 0;
    const [project, onChangeProject] = React.useState({
        "uuid": "",
        "name": "",
        "description": "",
        "date": "",
        "total_users": "",
        "contracts": "",
        "users": "",
        "userSate": "",
        "isOwner": "",
        "ownerUuid": ""
    });
    const {backUuid} = route.params;
    const updateProject = async () => {
        const payload = {
            name,
            description
        };
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.put(`${Api.baseUrl}/api/project/${backUuid}`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                showMessage({
                    message: "Succès !",
                    description: "Votre projet à bien été mis à jour.",
                    type: "success",
                    duration: 3000
                });
                // @ts-ignore
                return navigation.push('ProjectDetail', {uuid: backUuid});
            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    function backTo() {
        return 'ProjectDetail'
    }

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const projectFct = async () => {
        setLoading(true);
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/project/${backUuid}`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeProject(response.data.data)
                onChangeText(response.data.data.name)
                onChangeDescription(response.data.data.description)
                //setUrl(response.data.data.file)
                setTimeout(function () {
                    setLoading(false);
                }, 200);
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    useEffect(() => {
        isConnected().then()
        projectFct().then()
    }, [isFocused]);

    // @ts-ignore
    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText={project.name} titleTextTop="" navigation={navigation}  backTo={backTo()} paramBack={{'uuid': backUuid}}/>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <View style={styles.insideContainer}>
                        <View style={styles.textBlock}>
                        </View>
                        <TextInput
                            style={[name ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Nom du projet *"
                            onChangeText={onChangeText}
                            value={name}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <TextInput
                            style={[ styles.inputTextArea, styles.inputOK ]}
                            multiline
                            numberOfLines={8}
                            placeholder="Décrivez le projet"
                            onChangeText={onChangeDescription}
                            value={description}
                            textAlignVertical={'top'}
                            placeholderTextColor={'#000'}
                            autoCorrect={false}
                            returnKeyType='none'
                            autoCapitalize='none'
                            blurOnSubmit={false}
                            keyboardType="default"
                            onSubmitEditing={()=>{Keyboard.dismiss()}}
                        />
                        <TouchableOpacity
                            onPress={updateProject}
                            disabled={!checkFormData()}
                            style={checkFormData() ? styles.roundButton2 : styles.roundButton2No}>
                            <Text style={styles.textBold}>SAUVEGARDER</Text>
                        </TouchableOpacity>
                    </View>
                )}
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    hideError: {
        display: 'none'
    },
    showError: {
        color: '#df5952',
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    image: {
        flex: 1,
        alignItems: 'center'
    },
    back: {
        flex: 1,
        position: 'absolute',
        left: 1,
        top: 72
    },
    textB: {
        fontSize: 16,
        padding: 0,
        marginTop: -4,
        fontFamily: "Gotham",
    },
    logo: {
        width: 200,
        height: 70,
        margin: 70,
    },
    roundButton2: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#df5952',
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40
    },
    roundButton2No: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#CECECE',
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40
    },
    textBlock: {
        margin: 5,
        padding: 15,
        width: '100%',
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    input: {
        margin: 0,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    textBottomStep: {
        marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        bottom: 10,
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    placeholderT: {
        margin: 0,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        color: '#df5952',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    placeholder: {
        fontSize: 20,
        color: '#686868',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    selected: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    select: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    containerMultiple: {
        marginTop: 5,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        height: 1
    },
    containerListJob: {
        marginTop: 80,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    selectMultiple2: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000'
    },
    selectMultiple2Dp: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000',
    },
    selectMultiple2DpSelectd: {
        height: 180,
    },
    selectM: {
        margin: 12,
        marginTop: 5,
        borderBottomWidth: 1,
        padding: 0,
        paddingLeft: 8,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
        color: '#000'
    },
    inputBlack: {
        color: '#000'
    },
    delete: {
        marginTop: 15,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        marginBottom: 20,
        backgroundColor: '#df5952',
        fontFamily: "GothamBold",
    },
    checkboxContainer1: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 20,
        width: 360,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 10,
        width: 360,
    },
    checkbox: {

    },
    labelCheckbox: {
        margin: 8,
        fontFamily: "Gotham",
        color: '#000'
    },
    inputTextArea: {
        fontFamily: "Gotham",
        margin: 12,
        padding: 14,
        height: 150,
        width: 360,
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 150,
        alignSelf: 'center',
        borderWidth: 1,
        borderColor: '#000'
    },
});

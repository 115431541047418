import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    Animated,
    ActivityIndicator,
    Linking,
    Image,
    Dimensions,
    Platform,
    TouchableOpacity,
    AppState
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import FlashMessage, {showMessage} from "react-native-flash-message";
import axios from "axios";
import Api from "../constants/Api";
import WebView from "react-native-webview";
import Helper from "../components/Helper";
import {useIsFocused} from "@react-navigation/native";

export default function ContractDetailScreen({route, navigation}: RootStackScreenProps<'ContractDetail'>) {
    const [loading, setLoading] = React.useState(false);
    const [url, setUrl] = React.useState('');
    const [contract, onChangeContract] = React.useState({
        "uuid": "",
        "signature": "",
        "name": "",
        "state": "",
        "url": "",
    });
    // @ts-ignore
    const {uuid} = route.params;
    const {back} = route.params;
    const {backUuid} = route.params;
    const isSmallDevice = Dimensions.get('window').width < 768
    const webviewElement = []
    const isFocused = useIsFocused();
    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const contractFct = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/contract/${uuid}`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeContract(response.data.data)
                //setUrl(response.data.data.file)
            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }


    const signDoc = async () => {
        setLoading(true)
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/signature/${uuid}`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                setLoading(false)
                showMessage({
                    message: "Succès !",
                    description: "La procédure de signature est lancée.",
                    type: "success",
                    duration: 3000
                });
            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    const handleAppStateChange = async (data: any) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const uuidParameter = urlParams.get('uuid')
        if(data.data && data.data === 'MLT_DOCUMENT_FINISHED' && uuid == uuidParameter) {
            setLoading(true)
            const access_token = await AsyncStorage.getItem('access_token')
            await axios.get(`${Api.baseUrl}/api/finished/${uuid}`, {
                headers: {
                    'Authorization': 'Bearer ' + access_token
                }
            }).then(
                async function (response) {
                    await signDoc();
                    setLoading(false)
                    showMessage({
                        message: "Succès !",
                        description: "La rédaction du document est terminé.",
                        type: "success",
                        duration: 3000
                    });
                    // @ts-ignore
                    return navigation.push('ContractDetailShow', {uuid: uuid, backUuid: backUuid});
                }
            ).catch(
                function () {
                    showMessage({
                        message: "Oups !",
                        description: "Une erreur est survenue.",
                        type: "danger",
                    });
                }
            )
        } else if(data.data && data.data.message === 'DONE' && uuid == uuidParameter) {
            // @ts-ignore
            return navigation.push('ProjectDetail', {uuid: backUuid});
        }
    }

    function backTo() {
        return 'ProjectDetail'
    }

    useEffect(() => {
        isConnected().then()
        contractFct().then()
        if (navigator.appVersion.includes('Android')) {
            document.addEventListener("message", function (data) {
             handleAppStateChange(data)
            });
        }
        else {
            window.addEventListener("message", function (data) {
                handleAppStateChange(data)
            });
        }
    }, [isFocused]);

    if(Platform.OS === "web" ) {
        webviewElement.push(<iframe src={contract.url} height={'100%'} width={'100%'} frameBorder={0}/>)
    } else {
        webviewElement.push( <WebView style={!isSmallDevice ? styles.webview : styles.hide }
                 automaticallyAdjustContentInsets={true}
                 source={{ uri: contract.url }}
        />)
    }

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText={contract && contract.name ? contract.name : ''}  titleTextTop="" navigation={navigation} backTo={backTo()} paramBack={{'uuid': backUuid}}/>
                {loading && contract && contract.url ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <View style={styles.insideContainer}>
                        {webviewElement}
                    </View>
                )}
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 0,
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    item: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#d0d0d0',
        flex: 1,
        flexDirection: 'row'
    },
    imageStyle: {
        width: '100%',
        height:230,
        resizeMode: "cover"
    },
    webview: {
        padding: 5,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height
    },
    description: {
        width: '100%',
        padding: 8,
        marginTop: 15,
        fontSize: 16,
        fontFamily: "GothamBold",
    },
    hide: {
        display: 'none'
    },
    textInformation: {
        backgroundColor: '#313131',
        padding: 5,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height
    },
    textInside: {
        paddingTop: '50%',
        fontSize: 20,
        justifyContent: "center",
        alignItems: 'center',
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        textAlign: "center",
        color: '#FFF'
    },
    roundButton2: {
        width: 130,
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#C6362D',
        bottom: 10,
        position: 'absolute',
        fontFamily: "GothamBold",
        flexDirection: 'row',
        right: 15,
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        marginLeft: 5,
        right: 10,
        paddingTop: 0,
        position: 'absolute',
    },
    textBoldMobile: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        marginLeft: 5,
        right: 10,
        paddingTop: 10,
        position: 'absolute',
    },
    textBold2: {
        fontFamily: "GothamBold",
        fontSize: 20
    },
    iconSign: {
        width: 25,
        height: 25,
    },
    null: {

    }
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    Keyboard,
    TouchableOpacity,
    TextInput,
    Platform,
    Linking,
    Dimensions
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import Api from "../constants/Api";
import axios from "axios";
import qs from "querystring";
import FlashMessage, {showMessage} from "react-native-flash-message";
import WebView from "react-native-webview";

export default function FormContactScreen({navigation}: RootStackScreenProps<'FormContact'>) {
    const [message, onChangeText] = React.useState('');
    const checkFormData = () => message.length > 1;
    const webviewElement = []

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const contactFct = async () => {
        const payload = {
            message: message,
        };
        const access_token = await AsyncStorage.getItem('access_token')
        const response = await axios.post(`${Api.baseUrl}/api/contact`, qs.stringify(payload), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization' : 'Bearer ' + access_token
            }
        }).then(
            function () {
                showMessage({
                    message: "Merci !",
                    description: "Votre message à bien été envoyé.",
                    type: "success",
                    duration: 3000
                });
                navigation.navigate('Contact')
            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    if(Platform.OS === "web" ) {
        webviewElement.push(<iframe src="https://www.capeb.fr/contact" height={'100%'} width={'100%'} frameBorder={0} />)
    } else {
        webviewElement.push(    <WebView style={styles.webview}
                                         automaticallyAdjustContentInsets={true}
                                         source={{ uri: 'https://www.capeb.fr/contact' }}   />)
    }

    useEffect(() => {
        isConnected().then()
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="NOUS CONTACTER" titleTextTop="" navigation={navigation} backTo={'Logged'}/>
                <View style={styles.insideContainer}>
                    {webviewElement}
                </View>
            </View>
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    webview: {
        padding: 5,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height
    },
    container2: {
        flex: 1,
        width: '100%'
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    inputOK: {
    },
    inputKO: {
    },
    logo: {
        width: 200,
        height: 70,
        marginTop: 20,
        marginBottom: 25
    },
    text: {
        fontSize: 16,
        padding: 0,
        marginTop: -4,
        fontFamily: "Gotham",
    },
    textNoBold: {
        fontSize: 20,
        padding: 0,
        marginTop: -4,
        fontFamily: "Gotham",
    },
    textBlock: {
        margin: 25,
        padding: 15,
        width: '90%',
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    roundButton2: {
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#df5952',
        bottom: 50,
        position: 'absolute',
        fontFamily: "GothamBold",
    },
    textBold: {
        fontWeight: 'bold',
        fontSize: 20,
        color: "#fff",
        fontFamily: "GothamBold",
    },
    textBold2: {
        fontWeight: 'bold',
        fontSize: 16,
        fontFamily: "GothamBold",
    },
    barre: {
        left: 0,
        fontWeight: 'bold'
    },
    roundButton2No: {
        marginTop: 40,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#E4E4E5',
        bottom: 50,
        position: 'absolute',
        fontFamily: "GothamBold",
    },
    textarea: {
        padding: 10,
        width: '90%'
    },
    input: {
        fontFamily: "Gotham",
        margin: 12,
        padding: 14,
        width: '95%',
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        backgroundColor: '#F4F4F5',
        minHeight: 300,
        alignSelf: 'center',
    },
    placeholderT: {
        fontFamily: "Gotham",
        margin: 12,
        padding: 14,
        width: '95%',
        height: 55,
        fontSize: 20,
        color: '#484848',
        fontWeight: 'normal',
        backgroundColor: '#F4F4F5',
        minHeight: 300,
        alignSelf: 'center',
    },
});

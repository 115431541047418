import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    Animated,
    ActivityIndicator,
    Linking,
    Image,
    Dimensions,
    Platform,
    TouchableOpacity, Alert
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import FlashMessage, {showMessage} from "react-native-flash-message";
import axios from "axios";
import Api from "../constants/Api";
import Icon from "react-native-vector-icons/FontAwesome";
import {useIsFocused} from '@react-navigation/native';
import qs from "querystring";
import Helper from "../components/Helper";

export default function ProjectDetailScreen({route, navigation}: RootStackScreenProps<'ProjectDetail'>) {
    const [loading, setLoading] = React.useState(false);
    const [url, setUrl] = React.useState('');
    const [project, onChangeProject] = React.useState({
        "uuid": "",
        "name": "",
        "description": "",
        "date": "",
        "total_users": "",
        "contracts": "",
        "users": "",
        "userSate": "",
        "isOwner": "",
        "ownerUuid": "",
        "isCapeb": "",
        "proposal": ""
    });
    let noContent = <View style={styles.itemEmpty}/>;
    const listContract = [];
    const listUsers = [];
    // @ts-ignore
    const {uuid} = route.params;
    const {back} = route.params;
    const isFocused = useIsFocused();
    const regex = /(<([^>]+)>)/ig;
    const alertPolyfill = (title, description, options, extra) => {
        const result = window.confirm([title, description].filter(Boolean).join('\n'))

        if (result) {
            const confirmOption = options.find(({style}) => style !== 'cancel')
            confirmOption && confirmOption.onPress()
        } else {
            const cancelOption = options.find(({style}) => style === 'cancel')
            cancelOption && cancelOption.onPress()
        }
    }

    const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const onPressMobileNumberClick = (number) => {
        let phoneNumber = '';
        if (Platform.OS === 'android') {
            phoneNumber = `tel:${number}`;
        } else if (Platform.OS === 'ios') {
            phoneNumber = `telprompt:${number}`;
        } else {
            phoneNumber = `tel:${number}`;
        }

        Linking.openURL(phoneNumber);
    }

    const onWhatsApp = () => {
        Linking.openURL('whatsapp://send');
    }

    const projectFct = async () => {
        setLoading(true);
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/project/${uuid}`, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeProject(response.data.data)
                //setUrl(response.data.data.file)
                setTimeout(function () {
                    setLoading(false);
                }, 200);
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    async function deleteUser(member: string) {
        return alert(
            "Suppression",
            "Êtes-vous certain de vouloir supprimer ce membre ?",
            [
                {
                    text: "Oui",
                    onPress: () => {
                        deleteActionGet(member)
                    },
                },
                {
                    text: "Non",
                },
            ]
        );
    }

    async function deleteActionGet(member: string) {
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/member/delete/` + uuid + '/' + member, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            async function (response) {
                await projectFct();
                showMessage({
                    message: "Merci !",
                    description: "Le membre à bien été été supprimés",
                    type: "success"
                });
            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    function addUser(uuid: string) {
        if (project.contracts.length === 0) {
            return alert(
                "Attention",
                "Vous ne pouvez pas ajouter de membre avant d'avoir créé un document",
            );
        } else {
            navigation.navigate('Member', {uuid: uuid, back: 'ProjectDetail'})
        }
    }

    const createDocument = async () => {
        setLoading(true);
        const payload = {
            contract: 'e7cce1c9-f425-4a7b-9c81-5c65a30b2f96',
            name: 'Proposition de marché à partager',
            uuid
        };
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.post(`${Api.baseUrl}/api/contracts`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                showMessage({
                    message: "Succès !",
                    description: "Votre document à bien été créé.",
                    type: "success",
                    duration: 3000
                });
                setLoading(false);
                // @ts-ignore
                return navigation.push('ContractDetail', {uuid: response.data.uuid.uid, backUuid: uuid});
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    async function changeState(state: string) {
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/project-state/` + uuid + '/' + state, {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            async function (response) {
                await projectFct();
            }
        ).catch(
            function () {
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    function backTo() {
        return 'ProjectList'
    }

    useEffect(() => {
        isConnected().then()
        projectFct().then()
    }, [isFocused]);

    for (let i = 0; i < project.contracts.length; i++) {
        if (project.isOwner && project.contracts[i].status != 'signed' && project.contracts[i].status != 'finished') {
            listContract.push(
                <View style={styles.item}>
                    <TouchableOpacity style={styles.flex2} onPress={() => navigation.push('ContractDetail', {
                        uuid: project.contracts[i].uuid,
                        backUuid: project.uuid
                    })}>
                        <View style={styles.flex2}><Text
                            style={styles.category3}>• {project.contracts[i].name}</Text><Image
                            style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon5 : styles.iconWeb5]}
                            source={require('./../assets/images/edit.png')}
                        /></View>
                    </TouchableOpacity>
                </View>
            );
        } else {
            listContract.push(
                <View style={styles.item}>
                    <TouchableOpacity style={styles.flex2} onPress={() => navigation.push('ContractDetailShow', {
                        uuid: project.contracts[i].uuid,
                        backUuid: project.uuid
                    })}>
                        <View style={styles.flex2}><Text
                            style={styles.category2}>• {project.contracts[i].name}</Text><Image
                            style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon5 : styles.iconWeb5]}
                            source={require('./../assets/images/icon-see.png')}
                        /></View>
                    </TouchableOpacity>
                </View>
            );
        }
    }

    for (let i = 0; i < project.users.length; i++) {
        listUsers.push(
            <View style={styles.item}>
                <View style={styles.flex2}>
                    <TouchableOpacity style={[project.isOwner && project.users[i].uuid != project.ownerUuid ? styles.show : styles.hide]} onPress={() => {
                        onPressMobileNumberClick(project.users[i].phone)
                    }}>
                        <Icon name="phone" size={25} color="#C6362D" style={styles.iconTel}/>
                    </TouchableOpacity>
                    <Text
                        style={styles.category1}>{project.users[i].firstname} {project.users[i].lastname} {}
                        <View style={ project.isOwner ? '' : styles.hide}>
                            <Icon name="circle" size={25} color="#C6362D" style={[project.users[i].userState == 'yes' ? styles.iconYes : styles.hide ]}/>
                            <Icon name="circle" size={25} color="#df5952" style={[project.users[i].userState == 'no' ? styles.iconYes : styles.hide ]}/>
                            <Icon name="circle" size={25} color="#B0B0B0" style={[project.users[i].userState == 'read' ? styles.iconYes : styles.hide ]}/>
                        </View>
                    </Text>
                    <TouchableOpacity
                        style={[project.isOwner && project.users[i].uuid != project.ownerUuid ? styles.show : styles.hide]}
                        onPress={() => deleteUser(project.users[i].uuid)}
                    ><Image
                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon5 : styles.iconWeb5]}
                        source={require('./../assets/images/logo-delete.png')}
                    />
                    </TouchableOpacity>
                 </View>
            </View>
        );
    }

    if (project.contracts.length === 0) {
        noContent = <View style={styles.itemEmpty}>
            <Text style={styles.textEmpty}>Aucun document</Text>
        </View>
    }

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText={project && project.name ? project.name : ''} titleTextTop=""
                            navigation={navigation} backTo={backTo()}/>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <View style={styles.insideContainer}>
                        <View style={styles.firstbloc}>
                            <TouchableOpacity style={[project.isOwner ? styles.iconEdit : styles.hide]}
                                              onPress={() => navigation.push('ProjectEditScreen', {uuid: project.uuid, backUuid: project.uuid})}><Image
                                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                                source={require('./../assets/images/logo-edit.png')}
                            /></TouchableOpacity>
                            <View style={[styles.flex2]}><Image
                                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                                source={require('./../assets/images/logo-agenda-2.png')}
                            /><Text
                                style={[styles.textBold2, styles.mTp, styles.textWhite]}>{project.date}</Text></View>
                            <Text
                                style={[styles.textNormal, styles.textWhite, styles.descriptionProject]}>{project.description}</Text>
                        </View>
                        <View style={[project.userSate === 'yes' || project.userSate === 'no' ? styles.hide : styles.box2, project.isOwner ? styles.hide : styles.show]}>
                            <View style={styles.flex3}>
                                <Text>Êtes-vous intéressé par ce projet ? </Text>
                                <TouchableOpacity
                                    onPress={() => changeState('yes')}
                                ><Text style={styles.yes}>OUI</Text>
                                </TouchableOpacity>
                                <Text>   </Text>
                                <TouchableOpacity
                                    onPress={() => changeState('no')}
                                ><Text style={styles.no}>NON</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.box}>
                            <View style={styles.flex3}>
                                <Image
                                    style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon4 : styles.iconWeb4]}
                                    source={require('./../assets/images/logo-users.png')}
                                />
                                <Text style={styles.categoryContract}>{project.total_users} Membre(s) : </Text>
                                <TouchableOpacity
                                    onPress={() => addUser(uuid)}
                                    style={[project.isOwner ? styles.show : styles.hide, Platform.OS === 'ios' || Platform.OS === 'android' ? styles.alignRight : styles.alignRightDesktop]}
                                ><Image
                                    style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                                    source={require('./../assets/images/logo-add.png')}
                                />
                                </TouchableOpacity>
                            </View>
                            {listUsers}
                        </View>
                        <View style={styles.box}>
                            <View style={styles.flex3}>
                                <Image
                                    style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon4 : styles.iconWeb4]}
                                    source={require('./../assets/images/logo-documents.png')}
                                />
                                <Text style={styles.categoryContract}>Liste des document(s) : </Text>
                            </View>
                            {listContract}
                            {noContent}
                            <View style={[project.isOwner? styles.show : styles.hide]}>
                                <TouchableOpacity
                                    style={[ project.isCapeb && !project.proposal ? styles.roundButton6 : styles.hide]}
                                    onPress={() => createDocument()}>
                                    <Text style={styles.textBold3}>CRÉER UNE PROPOSITION DE TRAVAUX</Text>
                                </TouchableOpacity>
                                <TouchableOpacity  style={styles.roundButton2}
                                    onPress={() => navigation.navigate('Document', {uuid: uuid, back: 'ProjectDetail'})}>
                                    <Text style={styles.textBold3}>CRÉER UN
                                        DOCUMENT</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.insideContainer2}>
                            <TouchableOpacity
                                onPress={() => onWhatsApp()}
                                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.show : styles.hide, styles.roundButton3]}>
                                <Text style={styles.textBold}>CRÉER UN GROUPE WHAT'S APP</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                )}
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    show: {
        flex: 1,
        flexDirection: 'column'
    },
    hide: {
        display: 'none'
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        borderTopWidth: 0,
        padding: 10,
    },
    insideContainer2: {
        flex: 1,
        borderTopWidth: 0,
        padding: 10,
        alignItems: 'center'
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    item: {
        flexDirection: 'row',
        width: Dimensions.get('window').width - 100,
    },
    imageStyle: {
        width: '100%',
        height: 230,
        resizeMode: "cover"
    },
    category: {
        fontSize: 19,
        fontFamily: "GothamBold",
        paddingTop: 0,
        paddingBottom: 15
    },
    categoryContract: {
        fontSize: 19,
        fontFamily: "GothamBold",
        paddingTop: 12,
        marginBottom: 20,
        textTransform: 'uppercase'
    },
    category1: {
        fontSize: 18,
        fontFamily: "Gotham",
        marginTop: 2,
        marginBottom: 25,
        marginLeft: 30,
        width: '100%'
    },
    category2: {
        fontSize: 18,
        fontFamily: "Gotham",
        marginTop: 5,
        marginBottom: 25,
        width: '100%'
    },
    category3: {
        fontSize: 16,
        fontFamily: "Gotham",
        marginTop: 5,
        marginBottom: 25,
        width: '100%'
    },
    webview: {
        padding: 5,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height
    },
    description: {
        width: '100%',
        padding: 8,
        marginTop: 15,
        fontSize: 16,
        fontFamily: "GothamBold",
    },
    insideElement: {
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    roundButton2: {
        width: 350,
        height: 45,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#C6362D',
        fontFamily: "GothamBold",
        flexDirection: 'row',
        fontSize: 12,
        alignSelf: 'center',
    },
    roundButton6: {
        width: 350,
        height: 45,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        margin: 5,
        borderRadius: 5,
        backgroundColor: '#C6362D',
        fontFamily: "GothamBold",
        fontSize: 12,
        flexDirection: 'row',
        alignSelf: 'center',
    },
    roundButton3: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40,
        backgroundColor: '#03BB00',
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20
    },
    textBold3: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 16
    },
    textBold2: {
        fontFamily: "GothamBold",
        fontSize: 20,
    },
    textNormal: {
        fontSize: 20
    },
    flex2: {
        flexDirection: 'row',
        width: '100%'
    },
    flex3: {
        flexDirection: 'row',
        width: '100%'
    },
    icon: {
        marginTop: 5,
        marginLeft: 0,
        width: 35
    },
    iconTel: {
        marginTop: 4,
        marginLeft: 5,
        width: 30
    },
    icon2: {
        top: 16,
        marginLeft: 5,
        width: 40
    },
    icon2Web: {
        paddingTop: 15,
        top: 30,
        marginLeft: 5,
        width: 30
    },
    itemEmpty: {
        padding: 0,
        borderBottomWidth: 0,
        borderBottomColor: '#d0d0d0',
        height: 40
    },
    textEmpty: {
        marginTop: 10,
        fontFamily: "Gotham",
        fontSize: 12
    },
    mTp: {
        marginTop: 25
    },
    firstbloc: {
        backgroundColor: '#222220',
        borderRadius: 5
    },
    textWhite: {
        color: 'white'
    },
    icon3: {
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
    },
    iconWeb3: {
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
    },
    borderBottom: {
        borderBottomColor: 'white',
        borderBottomWidth: 1
    },
    descriptionProject: {
        paddingLeft: 15,
        paddingBottom: 15
    },
    box: {
        borderColor: '#B0B0B0',
        borderWidth: 1,
        borderRadius: 5,
        marginTop: 20,
        padding: 15
    },
    box2: {
        borderColor: '#B0B0B0',
        borderWidth: 1,
        borderRadius: 5,
        marginTop: 20,
        padding: 15,
        height: 10
    },
    icon4: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        marginLeft: 0,
    },
    iconWeb4: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
        marginLeft: 0,
    },
    icon5: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 4,
    },
    iconWeb5: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 4,
    },
    alignRight: {
        top: 0,
        right: -20,
        position: 'absolute',
    },
    alignRightDesktop: {
        top: 0,
        right: 10,
        position: 'absolute',
    },
    yes: {
        color: '#03BB00',
        textDecorationLine: "underline",
        fontFamily: "GothamBold",
    },
    no: {
        color: '#df5952',
        textDecorationLine: "underline",
        fontFamily: "GothamBold",
    },
    iconYes: {
        color: '#03BB00',
    },
    iconNo: {
        color: '#03BB00',
    },
    iconRead: {
        color: '#03BB00',
    },
    iconDefault: {
        color: '#03BB00',
    },
    iconEdit: {
        top: 10,
        right: 10,
        position: 'absolute',
        zIndex: 50
    },
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    TextInput,
    TouchableOpacity,
    ActivityIndicator,
    SafeAreaView, Platform, Alert, ScrollView, Dimensions
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import SelectPicker from "react-native-form-select-picker";
import axios from "axios";
import Api from "../constants/Api";
import qs from "querystring";
import Checkbox from 'react-native-checkbox';
import FlashMessage, {showMessage} from "react-native-flash-message";
import MultiSelect from 'react-native-multiple-select';
import WebView from "react-native-webview";
import Helper from "../components/Helper";

export default function DocumentScreen({route, navigation}: RootStackScreenProps<'Document'>) {
    const [contracts, onChangeContracts] = React.useState('');
    const [contract, onChangeContract] = React.useState('');
    const [isLocked, onChangeLocked] = React.useState('');
    const [offerUrl, onChangeOfferUrl] = React.useState('');
    const [name, onChangeText] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const webviewElement = []
    const checkFormData = () => contract && contract.length > 0 && name && name.length > 0;
    // @ts-ignore
    const {uuid} = route.params;
    const {back} = route.params;
    const isSmallDevice = Dimensions.get('window').width < 768
    const getData = async () => {
        setLoading(true);
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.get(`${Api.baseUrl}/api/contracts`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            async function (response) {
                if (response.data.data) {
                    await onChangeLocked(response.data.data.isLocked)
                    await onChangeOfferUrl(response.data.data.offerUrl)
                    await onChangeContracts(response.data.data.contracts)
                    setLoading(false);
                }
            }
        ).catch(
            function (reason) {
                showMessage({
                    message: "Une erreur est survenue.",
                    description: reason,
                    type: "danger",
                });
                setLoading(false);
            }
        )
    }

    const createDocument = async () => {
        setLoading(true);
        const payload = {
            contract,
            name,
            uuid
        };
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.post(`${Api.baseUrl}/api/contracts`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                showMessage({
                    message: "Succès !",
                    description: "Votre document à bien été créé.",
                    type: "success",
                    duration: 3000
                });
                onChangeText('')
                onChangeContract('')
                onChangeContracts('')
                setLoading(false);
                // @ts-ignore
                return navigation.push('ContractDetail', {uuid: response.data.uuid.uid, backUuid: uuid});
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    function backTo() {
        if (back && back.length > 0) {
            return back
        } else {
            return 'Logged'
        }

    }

    if (Platform.OS === "web") {
        webviewElement.push(<iframe src={offerUrl} height={'100%'} width={'100%'} frameBorder={0}/>)
    } else {
        webviewElement.push(<WebView style={styles.webview}
                                     automaticallyAdjustContentInsets={true}
                                     source={{uri: offerUrl}}
        />)
    }

    const handleAppStateChange = async (data: any) => {
        if(data.data.message == 'project') {
            // @ts-ignore
            navigation.navigate('ProjectDetail', {uuid: uuid, back: back})
        }
        if(data.data.message == 'contact') {
            // @ts-ignore
            navigation.navigate('FormContact', {uuid: uuid, back: back})
        }
    }

    useEffect(() => {
        if (navigator.appVersion.includes('Android')) {
            document.addEventListener("message", function (data) {
                handleAppStateChange(data)
            });
        }
        else {
            window.addEventListener("message", function (data) {
                handleAppStateChange(data)
            });
        }
        isConnected().then()
        getData().then(r => {
        });
    }, []);

    // @ts-ignore
    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="DOCUMENT" titleTextTop="" navigation={navigation} backTo={backTo()}
                            paramBack={{'uuid': uuid}}/>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    isLocked ? (
                        <View style={styles.insideContainer}>
                            {webviewElement}
                        </View>
                    ) : (
                        <View style={styles.insideContainer}>
                            <View style={styles.textBlock}>
                            </View>
                            <TextInput
                                style={[name ? styles.input : styles.placeholderT, styles.inputBlack]}
                                placeholder="Nom du document *"
                                onChangeText={onChangeText}
                                value={name}
                                placeholderTextColor={'#686868'}
                                autoCapitalize='none'
                                autoCorrect={false}
                            />
                            <SelectPicker
                                onValueChange={(value) => {
                                    onChangeContract(value);
                                }}
                                placeholder="Type *"
                                placeholderStyle={styles.placeholder}
                                style={styles.select}
                                selected={contract}
                                // @ts-ignore
                                onSelectedStyle={styles.selected}
                                doneButtonText={'OK'}
                            >
                                {Object.values(contracts).map((val, index) => (
                                    <SelectPicker.Item label={val.name} value={val.id_redaction_model_catalog}
                                                       key={index}/>
                                ))}
                            </SelectPicker>
                            <TouchableOpacity
                                onPress={createDocument}
                                disabled={!checkFormData()}
                                style={checkFormData() ? styles.roundButton2 : styles.roundButton2No}>
                                <Text style={styles.textBold}>CRÉER</Text>
                            </TouchableOpacity>
                        </View>
                    )
                )}
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    hideError: {
        display: 'none'
    },
    showError: {
        color: '#df5952',
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    image: {
        flex: 1,
        alignItems: 'center'
    },
    back: {
        flex: 1,
        position: 'absolute',
        left: 1,
        top: 72
    },
    textB: {
        fontSize: 16,
        padding: 0,
        marginTop: -4,
        fontFamily: "Gotham",
    },
    logo: {
        width: 200,
        height: 70,
        margin: 70,
    },
    roundButton2: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#C6362D',
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40
    },
    roundButton2No: {
        marginTop: 10,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#CECECE',
        fontFamily: "GothamBold",
        position: 'absolute',
        bottom: 40
    },
    textBlock: {
        margin: 5,
        padding: 5,
        width: '100%',
        fontWeight: 'bold',
        fontSize: 18,
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    input: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    textBottomStep: {
        marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        bottom: 10,
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    placeholderT: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        color: '#df5952',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    placeholder: {
        fontSize: 20,
        color: '#686868',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    selected: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    select: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    containerMultiple: {
        marginTop: 5,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        height: 1
    },
    containerListJob: {
        marginTop: 80,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    selectMultiple2: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000'
    },
    selectMultiple2Dp: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        color: '#000',
    },
    selectMultiple2DpSelectd: {
        height: 180,
    },
    selectM: {
        margin: 12,
        marginTop: 5,
        borderBottomWidth: 1,
        padding: 0,
        paddingLeft: 8,
        width: 360,
        height: 55,
        fontFamily: "GothamBold",
        color: '#000'
    },
    inputBlack: {
        color: '#000'
    },
    delete: {
        marginTop: 15,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        marginBottom: 20,
        backgroundColor: '#df5952',
        fontFamily: "GothamBold",
    },
    checkboxContainer1: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 20,
        width: 360,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 10,
        width: 360,
    },
    checkbox: {},
    labelCheckbox: {
        margin: 8,
        fontFamily: "Gotham",
        color: '#000'
    },
    webview: {
        padding: 5,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height
    },
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    Image,
    ImageBackground,
    TouchableOpacity,
    TextInput,
    Platform, Dimensions, Keyboard, ActivityIndicator
} from 'react-native';
import Checkbox from 'expo-checkbox';
import {RootStackScreenProps} from "../types";
import Api from "../constants/Api";
import axios from "axios";
import SelectPicker from 'react-native-form-select-picker';
import qs from "querystring";
import AsyncStorage from "@react-native-async-storage/async-storage";
import FlashMessage, {showMessage} from "react-native-flash-message";
import {decode as atob64} from 'base-64'
import MultiSelect from "react-native-multiple-select";

export default function RegisterScreen({route, navigation}: RootStackScreenProps<'Register2'>) {
    const [jobs, setJobs] = React.useState('');
    const [isAnnuaire, setAnnuaire] = React.useState(false);
    const [isCapeb, setCapeb] = React.useState(false);
    const [department, setDepartment] = React.useState();
    const [firstname, onChangeFirstname] = React.useState('');
    const [lastname, onChangeLastname] = React.useState('');
    const [description, onChangeDescription] = React.useState('');
    const [capebNumber, onChangeCapebNumber] = React.useState('');
    const isSmallDevice = Dimensions.get('window').width < 768
    const [loading, setLoading] = React.useState(false);
    // @ts-ignore
    const {email, password, company, siret, phone} = route.params;
    const checkFormData = () => selectedItems && selectedItems.length > 0 && firstname && firstname.length > 0 && lastname && lastname.length > 0 && department && department.length > 0;
    const [data, setData] = React.useState({
            jobs: [{
                id: 0,
                name: ''
            }],
            dept: [{
                id: 0,
                name: ''
            }]
        }
    );
    const [selectedItems, setSelectedItems] = React.useState([]);

    const onSelectedItemsChange = (selectedItems: React.SetStateAction<never[]>) => {
        setSelectedItems(selectedItems);
    };

    useEffect(() => {
        getData().then(r => {
        });
    }, []);

    const getData = async () => {
        await axios.get(`${Api.baseUrl}/api/constants`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(
            async function (response) {
                if (response.data.data) {
                    await setData(response.data.data)
                    await setData(response.data.data)
                    // @ts-ignore
                    setAnnuaire({isChecked: true})
                }
            }
        );
    }

    const createAccount = async () => {
        let email_decoded = atob64(email).toLowerCase()
        let company_decoded = atob64(company)
        let siret_decoded = atob64(siret)
        let phone_decoded = atob64(phone)
        const payload = {
            email: email_decoded,
            password,
            company: company_decoded,
            siret: siret_decoded,
            phone: phone_decoded,
            jobs: selectedItems.join(','),
            isAnnuaire,
            isCapeb,
            department,
            firstname,
            lastname,
            description,
            capebNumber,
        };
        setLoading(true);
        await axios.post(`${Api.baseUrl}/api/user/create`, qs.stringify(payload), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(
            async function (response) {
                if (response.data.status === 0) {
                    const payload = {
                        grant_type: 'password',
                        username: email_decoded.toLowerCase(),
                        password: atob64(password),
                        client_id: Api.clientId,
                        client_secret: Api.clientSecret
                    };
                    await axios.post(`${Api.baseUrl}/token`, qs.stringify(payload), {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(
                        function (response) {
                            if (response.data.access_token) {
                                AsyncStorage.setItem('access_token', response.data.access_token)
                                navigation.navigate('Logged')
                            }
                        }
                    )
                } else if (response.data.status === 1) {
                    setLoading(false);
                    showMessage({
                        message: "Oups !",
                        description: "Cette adresse email est déjà utilisée.",
                        type: "danger",
                    });
                } else if (response.data.status === 2) {
                    setLoading(false);
                    showMessage({
                        message: "Oups !",
                        description: "Votre numéro d'adhérent est invalide.",
                        type: "danger",
                    });
                }
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue lors de la création du compte.",
                    type: "danger",
                });
            }
        )
    }

    return (
        <View style={styles.container}>
            <ImageBackground source={require('./../assets/images/background-flou.png')} resizeMode="cover"
                             style={styles.image}>
                <View
                    style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.containerh : styles.containerweb}>
                    <View style={styles.width20}>
                        <TouchableOpacity onPress={() => navigation.navigate('Register')}
                                          style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.backsmall : styles.back]}>
                            <Image
                                source={require('./../assets/images/back-arrow.png')}
                                style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.backarrow : styles.backarrowweb}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.width60}>
                        <Image
                            style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logo : styles.logoweb}
                            source={require('./../assets/images/logo_capeb.png')}
                        />
                    </View>
                    <View style={styles.width20}>

                    </View>
                </View>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <View style={styles.insideContainer}>

                        <TextInput
                            style={[firstname ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Votre prénom *"
                            onChangeText={onChangeFirstname}
                            value={firstname}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <TextInput
                            style={[lastname ? styles.input : styles.placeholderT, styles.inputBlack]}
                            placeholder="Votre nom *"
                            onChangeText={onChangeLastname}
                            value={lastname}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <SelectPicker
                            onValueChange={(value) => {
                                setDepartment(value);
                            }}
                            placeholder="Votre département *"
                            placeholderStyle={styles.placeholder}
                            style={styles.select}
                            selected={department}
                            // @ts-ignore
                            onSelectedStyle={styles.selected}
                            doneButtonText={'OK'}
                        >
                            {Object.values(data.dept).map((val, index) => (
                                <SelectPicker.Item label={val.name} value={val.id} key={index}/>
                            ))}
                        </SelectPicker>
                        <MultiSelect
                            hideTags
                            items={data.jobs}
                            uniqueKey="id"
                            onSelectedItemsChange={onSelectedItemsChange}
                            selectedItems={selectedItems}
                            selectText="Métiers"
                            searchInputPlaceholderText="Rechercher un métier..."
                            tagRemoveIconColor="#000"
                            tagBorderColor="#000"
                            tagTextColor="#000"
                            selectedItemTextColor="#000"
                            selectedItemIconColor="#000"
                            itemTextColor="#000"
                            displayKey="name"
                            altFontFamily="Gotham"
                            noItemsText="Aucun métier trouvé..."
                            searchInputStyle={styles.textSearch}
                            submitButtonColor="#636363"
                            submitButtonText="OK"
                            styleDropdownMenu={styles.selectM}
                            styleInputGroup={styles.selectM}
                            styleTextDropdown={styles.selectMultiple2Dp}
                            styleTextDropdownSelected={styles.selectMultiple2}
                            styleListContainer={styles.selectMultiple2DpSelectd}
                            styleDropdownMenuSubsection={styles.transparentDopdown}
                            styleSelectorContainer={styles.transparent}
                            styleItemsContainer={styles.transparent}
                            styleMainWrapper={styles.white}
                            styleRowList={styles.transparent}
                            style={styles.topElement}
                        />
                        <View style={styles.checkboxContainer1}>
                            <Checkbox
                                style={styles.checkbox}
                                value={isCapeb}
                                onValueChange={setCapeb}
                            />
                            <Text style={styles.labelCheckbox}>Êtes vous adhérent de la CAPEB ?</Text>
                        </View>
                        <TextInput
                            style={[isCapeb ? styles.inputC : styles.inputTextAreNoneC]}
                            placeholder="Votre numéro CAPEB *"
                            onChangeText={onChangeCapebNumber}
                            value={capebNumber}
                            placeholderTextColor={'#686868'}
                            autoCapitalize='none'
                            autoCorrect={false}
                        />
                        <View style={styles.checkboxContainer}>
                            <Checkbox
                                style={styles.checkbox}
                                value={isAnnuaire}
                                onValueChange={setAnnuaire}
                            />
                            <Text style={styles.labelCheckbox}>Apparaitre dans notre annuaire professionnel ?</Text>
                        </View>
                        <TextInput
                            style={[isAnnuaire ? styles.inputTextArea : styles.inputTextAreNone, description.length > 0 ? styles.inputKO : styles.inputOK]}
                            multiline
                            numberOfLines={5}
                            placeholder="Décrivez vos compétences, vos spécialités ou encore vos zones d'intervention"
                            onChangeText={onChangeDescription}
                            value={description}
                            textAlignVertical={'top'}
                            placeholderTextColor={'#000'}
                            autoCorrect={false}
                            returnKeyType='none'
                            autoCapitalize='none'
                            blurOnSubmit={false}
                            keyboardType="default"
                            onSubmitEditing={() => {
                                Keyboard.dismiss()
                            }}
                        />
                        <TouchableOpacity
                            onPress={createAccount}
                            disabled={!checkFormData()}
                            style={checkFormData() ? styles.roundButton2 : styles.roundButton2No}>
                            <Text style={styles.textBold}>VALIDER</Text>
                        </TouchableOpacity>
                    </View>
                )}
            </ImageBackground>
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'}/>
            <FlashMessage position="top"/>
        </View>
    );
}

const styles = StyleSheet.create({
    containerh: {
        flexDirection: 'row',
        height: 120,
        backgroundColor: '#000',
        width: '100%',
    },
    backarrow: {
        width: 50,
        height: 50,
        marginLeft: 0,
    },
    backarrowweb: {
        width: 50,
        height: 50,
        marginLeft: 0,
        marginTop: 6,
    },
    containerweb: {
        flexDirection: 'row',
        height: 90,
        backgroundColor: '#000',
        width: '100%'
    },
    logo: {
        width: 160,
        height: 60,
        marginLeft: -10,
        marginTop: 50,
    },
    logosmall: {
        width: 200,
        height: 70,
        marginTop: 45,
        alignItems: 'center'
    },
    back: {
        left: 1,
        top: 15,
        width: 40,
        height: 40,
    },
    backsmall: {
        left: 0,
        top: 56,
        width: 40,
        height: 40,
    },
    logoBack: {
        left: 5,
        top: 6,
        width: 35,
        height: 35
    },
    logoBackMobile: {
        left: 5,
        top: 32,
        zIndex: 10,
        width: 35,
        height: 48
    },
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    logoweb: {
        width: 160,
        height: 60,
        marginLeft: -10,
        marginTop: 10,
    },
    width20: {
        width: '10%',
    },
    width60: {
        width: '80%',
        alignItems: 'center'
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    image: {
        flex: 1,
        alignItems: 'center'
    },
    roundButton2: {
        marginTop: 40,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#df5952',
        fontFamily: "Gotham",
    },
    roundButton2No: {
        marginTop: 40,
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#CECECE',
        fontFamily: "Gotham",
    },
    textBold: {
        color: 'white',
        fontSize: 20,
        fontFamily: "Gotham",
    },
    topNeg: {
        marginTop: -10,
    },
    top: {
        marginTop: 10,
    },
    input: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        fontFamily: "Gotham",
    },
    inputC: {
        margin: 12,
        marginTop: -16,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        fontFamily: "Gotham",
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    select: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontFamily: "Gotham",
    },
    textBottomStep: {
        marginTop: 40,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        bottom: 10,
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: "Gotham",
    },
    label: {
        fontFamily: "Gotham",
    },
    placeholder: {
        fontSize: 20,
        color: '#686868',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    selected: {
        fontSize: 20,
        fontFamily: "Gotham",
    },
    placeholderT: {
        margin: 12,
        borderBottomWidth: 1,
        padding: 10,
        width: 360,
        height: 55,
        fontSize: 20,
        color: '#df5952',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    containerMultiple: {
        marginTop: 5,
        fontSize: 20,
        fontFamily: "Gotham",
        height: 1,
        backgroundColor: 'transparent',
        zIndex: 999999999,
    },
    selectMultiple2: {
        fontSize: 20,
        fontFamily: "Gotham",
        color: '#000',
        backgroundColor: 'transparent',
        borderWidth: 0,
        borderRadius: 10,
        zIndex: 999999999,
    },
    selectMultiple2Dp: {
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "Gotham",
        color: '#686868',
        backgroundColor: 'transparent',
        borderWidth: 0,
        borderRadius: 10,
        zIndex: 999999999,
    },
    selectMultiple2DpSelectd: {
        height: 180,
        backgroundColor: 'transparent',
        borderWidth: 0,
        borderRadius: 10,
        zIndex: 999999999,
    },
    selectM: {
        backgroundColor: 'transparent',
        margin: 12,
        paddingBottom: 1,
        marginTop: 5,
        borderBottomWidth: 1,
        padding: 0,
        paddingLeft: 8,
        width: 360,
        height: 55,
        fontFamily: "Gotham",
        color: '#000',
        zIndex: 999999999,
    },
    transparent: {
        backgroundColor: 'transparent',
        borderWidth: 0,
    },
    textSearch: {
        color: '#000',
        fontSize: 18,
        fontWeight: 'normal',
        fontFamily: "Gotham",
        zIndex: 999999999,
    },
    red: {
        backgroundColor: 'red',
    },
    transparentDopdown: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderWidth: 0,
    },
    white: {
        zIndex: 9,
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    inputBlack: {
        color: '#000',
    },
    topElement: {
        zIndex: 999999999,
    },
    checkboxContainer1: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 20,
        width: 360,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 20,
        marginTop: 10,
        width: 360,
    },
    checkbox: {
        marginTop: 6,
    },
    labelCheckbox: {
        margin: 8,
        fontFamily: "Gotham",
        color: '#000'
    },
    header: {
        backgroundColor: '#313131',
        width: '100%',
        alignItems: 'center',
        height: 130,
        marginBottom: 50
    },
    inputTextArea: {
        fontFamily: "Gotham",
        margin: 12,
        marginTop: 2,
        padding: 6,
        width: '88%',
        maxWidth: 360,
        height: 150,
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 120,
        alignSelf: 'center',
        borderWidth: 1,
        borderColor: '#000'
    },
    inputTextAreNone: {
        fontFamily: "Gotham",
        margin: 0,
        padding: 0,
        width: '0%',
        height: 0,
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 0,
        alignSelf: 'center',
    },
    inputTextAreNoneC: {
        fontFamily: "Gotham",
        margin: 0,
        padding: 0,
        width: '0%',
        height: 0,
        fontSize: 16,
        fontWeight: 'bold',
        minHeight: 0,
        alignSelf: 'center',
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 0,
    },
});

// @ts-ignore

import {Image, StyleSheet, Text, View, TouchableOpacity, ScrollView} from 'react-native';
import React from "react";

export type HelperProps = { navigation?:any };

class Helper extends React.Component<HelperProps> {
        render () {
        return (
            <TouchableOpacity
                onPress={() =>  this.props.navigation.navigate('Form')}
                style={styles.roundButton3}>
                <Text style={styles.textBold}>?</Text>
            </TouchableOpacity>
        );
    }
}

export default Helper;

const styles = StyleSheet.create({
    roundButton3: {
        width: 55,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 50,
        backgroundColor: '#C6362D',
        bottom: 20,
        right: 20,
        position: 'absolute',
        fontFamily: "GothamBold",
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 25,
        fontFamily: "GothamBold",
    },
});

import {NavigationContainer, DefaultTheme} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import * as React from 'react';
import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/LoginScreen';
import RegisterScreen from '../screens/RegisterScreen';
import RegisterScreen2 from '../screens/RegisterScreen2';
import LoggedScreen from '../screens/LoggedScreen';
import {RootStackParamList, RootTabParamList, RootTabScreenProps} from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import ProfileScreen from "../screens/ProfileScreen";
import ContactScreen from "../screens/ContactScreen";
import PasswordScreen from "../screens/PasswordScreen";
import FormScreen from "../screens/FormScreen";
import ActuDetailScreen from "../screens/ActuDetailScreen";
import SearchScreen from "../screens/SearchScreen";
import ProjectScreen from "../screens/ProjectScreen";
import DocumentScreen from "../screens/DocumentScreen";
import ProjectListScreen from "../screens/ProjectListScreen";
import ProjectDetailScreen from  "../screens/ProjectDetailScreen";
import AnnuaireListScreen from "../screens/AnnuaireListScreen";
import ContractDetailScreen from "../screens/ContractDetailScreen";
import MemberScreen from "../screens/MemberScreen";
import FormContactScreen from "../screens/FormContactScreen";
import ContractDetailShowScreen from "../screens/ContractDetailShowScreen";
import InviteUserScreen from "../screens/InviteUserScreen"
import ProjectEditScreen from "../screens/ProjectEditScreen"
import BourseListScreen from "../screens/BourseListScreen"
import FormMarche from "../screens/FormMarche";
import BourseDetailScreen from "../screens/BourseDetailScreen";
import BourseDetailShowScreen from "../screens/BourseDetailShowScreen";

export default function Navigation() {
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={DefaultTheme}>
            <RootNavigator/>
        </NavigationContainer>
    );
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
    return (
        <Stack.Navigator initialRouteName="Home">
            <Stack.Screen name="Login" component={LoginScreen} options={{title: 'Connexion',headerShown: false}}/>
            <Stack.Screen name="Register" component={RegisterScreen} options={{title: 'Inscription',headerShown: false}}/>
            <Stack.Screen name="Register2" component={RegisterScreen2} options={{title: 'Inscription',headerShown: false}}/>
            <Stack.Screen name="Logged" component={LoggedScreen} options={{title: 'CAPEB',headerShown: false}}/>
            <Stack.Screen name="Home" component={HomeScreen} options={{title: 'CAPEB',headerShown: false}}/>
            <Stack.Screen name="Profile" component={ProfileScreen} options={{title: 'Mon compte',headerShown: false}}/>
            <Stack.Screen name="Contact" component={ContactScreen} options={{title: 'Contact',headerShown: false}}/>
            <Stack.Screen name="Password" component={PasswordScreen} options={{title: 'Mot de passe oublié',headerShown: false}}/>
            <Stack.Screen name="Form" component={FormScreen} options={{title: 'Détail du document',headerShown: false}}/>
            <Stack.Screen name="ActuDetail" component={ActuDetailScreen} options={{title: 'Actualité',headerShown: false}}/>
            <Stack.Screen name="Search" component={SearchScreen} options={{title: 'Recherche',headerShown: false}}/>
            <Stack.Screen name="ProjectList" component={ProjectListScreen} options={{title: 'Liste des groupements',headerShown: false}}/>
            <Stack.Screen name="Project" component={ProjectScreen} options={{title: 'Créer un dossier',headerShown: false}}/>
            <Stack.Screen name="ProjectDetail" component={ProjectDetailScreen} options={{title: 'Détail du groupement',headerShown: false}}/>
            <Stack.Screen name="ProjectEditScreen" component={ProjectEditScreen} options={{title: 'Edition du groupement',headerShown: false}}/>
            <Stack.Screen name="Document" component={DocumentScreen} options={{title: 'Créer un contrat',headerShown: false}}/>
            <Stack.Screen name="AnnuaireList" component={AnnuaireListScreen} options={{title: 'Annuaire',headerShown: false}}/>
            <Stack.Screen name="ContractDetail" component={ContractDetailScreen} options={{title: 'Détail du contrat', headerShown: false}}/>
            <Stack.Screen name="Member" component={MemberScreen} options={{title: 'Ajouter un membre',headerShown: false}}/>
            <Stack.Screen name="FormContact" component={FormContactScreen} options={{title: 'Contact',headerShown: false}}/>
            <Stack.Screen name="ContractDetailShow" component={ContractDetailShowScreen} options={{title: 'Détail du document',headerShown: false}}/>
            <Stack.Screen name="InviteUser" component={InviteUserScreen} options={{title: 'Invitation',headerShown: false}}/>
            <Stack.Screen name="FormMarche" component={FormMarche} options={{title: 'Formulaire de proposition de travaux',headerShown: false}}/>
            <Stack.Screen name="BourseList" component={BourseListScreen} options={{title: 'Bourse de travaux',headerShown: false}}/>
            <Stack.Screen name="BourseDetail" component={BourseDetailScreen} options={{title: 'Créer une bourse de travaux',headerShown: false}}/>
            <Stack.Screen name="BourseDetailShowScreen" component={BourseDetailShowScreen} options={{title: 'Créer une bourse de travaux',headerShown: false}}/>
        </Stack.Navigator>
    );
}

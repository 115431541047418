// @ts-ignore

import {Image, StyleSheet, Text, View, TouchableOpacity, ScrollView} from 'react-native';
import React from "react";
import {Platform} from 'react-native';

export type menuProps = { backTo?:any, navigation?:any, onChangeIsOpen?:any, titleText?:string, titleTextTop:string, paramBack?:object };

class HeaderMini extends React.Component<menuProps> {
    render () {
        return (
            <View style={this.props.titleTextTop != '' && !this.props.paramBack ? styles.containerBig : Platform.OS === 'ios' || Platform.OS === 'android' ? styles.container : styles.containerweb}>
                <TouchableOpacity onPress={() => this.props.backTo ? this.props.navigation.navigate(this.props.backTo,  this.props.paramBack) : this.props.navigation.navigate('Logged')} style={styles.back}>
                    <Image
                        source={require('./../assets/images/back-arrow.png')} style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.logoBackMobile : styles.logoBack}/>
                </TouchableOpacity>
                <View style={Platform.OS === 'ios' || Platform.OS === 'android' ? styles.container2 : styles.container1}>
                    <Text style={this.props.titleTextTop != '' ? styles.titleMini : styles.notitleMini}>{this.props.titleTextTop}</Text>
                    <Text numberOfLines={2} style={this.props.titleTextTop != '' ? styles.titleTop : styles.title}>{this.props.titleText}</Text>
                </View>
            </View>
        );
    }
}

export default HeaderMini;

const styles = StyleSheet.create({
    containerweb: {
        flexDirection: 'row',
        height: 70,
        backgroundColor: '#000',
    },
    container: {
        flexDirection: 'row',
        height: 100,
        backgroundColor: '#000',
    },
    containerBig: {
        flexDirection: 'row',
        height: 120,
        backgroundColor: '#000',
    },
    container1: {
        flexDirection: 'column',
        height: 80,
        paddingTop: 20,
        paddingLeft: 20
    },
    container2: {
        flexDirection: 'column',
        height: 120,
        marginLeft: -40,
    },
    logo: {
        width: 160,
        height: 60,
        marginLeft: 10,
        marginTop: 50,
    },
    burger: {
        width: 40,
        height: 40,
        marginTop: 56,
    },
    search: {
        width: 25,
        height: 25,
        marginTop: 8,
        marginLeft: 8
    },
    searchContainer: {
        width: 44,
        height: 44,
        borderRadius: 100,
        backgroundColor: '#F4F4F4',
        marginTop: 58,
        zIndex: 0
    },
    back: {
        left: 1,
        top: 10,
        zIndex: 10,
        width: 40,
        height: 40
    },
    logoBack: {
        left: 5,
        top: 6,
        zIndex: 10,
        width: 40,
        height: 35
    },
    logoBackMobile: {
        left: 5,
        top: 32,
        zIndex: 10,
        width: 40,
        height: 48
    },
    width20: {
        width: '20%',
        alignItems: 'center'
    },
    width60: {
        width: '60%',
        alignItems: 'center'
    },
    title: {
        top: 52,
        left: 50,
        fontSize: 24,
        fontWeight: 'bold',
        color: "#FFF",
        fontFamily: "GothamBold",
        textTransform: "uppercase"
    },
    titleTop: {
        top: 30,
        left: 50,
        fontSize: 20,
        fontWeight: 'bold',
        color: "#FFF",
        fontFamily: "GothamBold",
        textTransform: "uppercase",
        width: 320,
        flexWrap: 'wrap'
    },
    titleMini: {
        top: 40,
        left: 50,
        fontSize: 16,
        fontWeight: 'bold',
        color: "#FFF",
        fontFamily: "GothamBold",
        textTransform: "uppercase",
    },
    notitleMini: {
        display: "none"
    },
    barre: {
        top: 55,
        left: 50,
    },
    barreTop: {
        top: 45,
        left: 50,
    }
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    TouchableOpacity,
    ActivityIndicator, Platform, TextInput, Image
} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import axios from "axios";
import Api from "../constants/Api";
import FlashMessage, {showMessage} from "react-native-flash-message";
import qs from "querystring";
import {useIsFocused} from '@react-navigation/native';
import Helper from "../components/Helper";

export default function ProjectListScreen({route, navigation}: RootStackScreenProps<'BourseList'>) {
    const [projects, onChangeProjects] = React.useState('');
    const [isCapeb, onChangeCapeb] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [search, onChangeSearch] = React.useState('');
    const listProject = [];
    let notCapeb = <View style={styles.itemEmpty}/>;
    let noContent = <View style={styles.itemEmpty}/>;
    const isFocused = useIsFocused();


    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }

    const updateSearch = async (loader: boolean, ev: string) => {
        onChangeSearch(ev)
        await dataFct(false, ev)
    }

    const dataFct = async (loader: boolean, search: string) => {
        if (loader) {
            setLoading(true);
        }
        const access_token = await AsyncStorage.getItem('access_token')
        let payload = {}
        if (search && search.length > 0) {
            payload = {search}
        }
        await axios.post(`${Api.baseUrl}/api/bourses`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                onChangeProjects(response.data.bourses)
                onChangeCapeb(response.data.isCapeb)
                setTimeout(function () {
                    setLoading(false);
                }, 50);
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    function backTo() {
        return 'Logged'
    }

    const createBourse = async () => {
        setLoading(true);
        const payload = {
            action : 'create'
        };
        const access_token = await AsyncStorage.getItem('access_token')
        await axios.post(`${Api.baseUrl}/api/bourse`, qs.stringify(payload), {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        }).then(
            function (response) {
                showMessage({
                    message: "Succès !",
                    description: "La proposition est créée.",
                    type: "success",
                    duration: 3000
                });
                setLoading(false);
                // @ts-ignore
                return navigation.push('BourseDetail', {uuid: response.data.uuid.uid});
            }
        ).catch(
            function () {
                setLoading(false);
                showMessage({
                    message: "Oups !",
                    description: "Une erreur est survenue.",
                    type: "danger",
                });
            }
        )
    }

    useEffect(() => {
        isConnected().then()
        dataFct(true, '').then()
    }, [isFocused]);

    for (let i = 0; i < projects.length; i++) {
        if( projects[i].owner) {
            listProject.push(
                <TouchableOpacity
                    onPress={() => navigation.push('BourseDetail', {uuid: projects[i].uuid})}>
                    <View style={styles.item}>
                        <TouchableOpacity style={styles.icon2}
                                          onPress={() => navigation.push('BourseDetail', {uuid: projects[i].uuid})}><Image
                            style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                            source={require('./../assets/images/logo-edit.png')}
                        /></TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.push('BourseDetail', {uuid: projects[i].uuid})}>
                            <View style={[styles.flex2]}><Image
                                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon5 : styles.iconWeb5]}
                                source={require('./../assets/images/bourse.png')}
                            /><Text
                                style={styles.category}>{projects[i].data && projects[i].data.loc_chantier_ville ? projects[i].data.loc_chantier_ville : ''}</Text></View>
                            <View style={[styles.flex2, styles.borderBottom]}><Text
                                style={styles.category2}>Publié par {projects[i].author} le {projects[i].date}</Text></View>
                        </TouchableOpacity>
                    </View>
                </TouchableOpacity>
            );
        } else if(isCapeb) {
            if(projects[i].data && projects[i].data.loc_chantier_ville && projects[i].data && projects[i].data.dpt && projects[i].data && projects[i].data.lots ) {
                listProject.push(
                    <TouchableOpacity
                        onPress={() => navigation.push('BourseDetailShowScreen', {uuid: projects[i].uuid})}>
                        <View style={styles.item}>
                            <TouchableOpacity style={styles.icon2}
                                              onPress={() => navigation.push('BourseDetailShowScreen', {uuid: projects[i].uuid})}><Image
                                style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                                source={require('./../assets/images/eye-3-xxl.png')}
                            /></TouchableOpacity>
                            <TouchableOpacity onPress={() => navigation.push('BourseDetailShowScreen', {uuid: projects[i].uuid})}>
                                <View style={[styles.flex2]}><Image
                                    style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon5 : styles.iconWeb5]}
                                    source={require('./../assets/images/bourse.png')}
                                /><Text
                                    style={styles.category}>{projects[i].data.loc_chantier_ville}</Text></View>
                                <View style={styles.flex3}>
                                    <Text style={styles.font20}>Département : {typeof projects[i].data.dpt  !== 'undefined'? projects[i].data.dpt : ''}</Text>
                                </View>
                                <View style={styles.flex3}>
                                    <Text style={styles.font20}>Nombres de lots : {typeof projects[i].data.lots !== 'undefined'? projects[i].data.lots : ''}</Text>
                                </View>
                                <View style={styles.flex3}>
                                    <Text style={styles.font20}>{typeof projects[i].data.info_bati_type_travaux !== 'undefined' ? projects[i].data.info_bati_type_travaux : ''}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </TouchableOpacity>
                );
            }
        } else {
            if(projects[i].data && projects[i].data.loc_chantier_ville && projects[i].data && projects[i].data.dpt && projects[i].data && projects[i].data.lots ) {
                listProject.push(
                    <TouchableOpacity>
                        <View style={styles.item}>
                            <TouchableOpacity>
                                <View style={[styles.flex2]}><Image
                                    style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon5 : styles.iconWeb5]}
                                    source={require('./../assets/images/bourse.png')}
                                /><Text
                                    style={styles.category}>{projects[i].data.loc_chantier_ville}</Text></View>
                                <View style={[styles.flex2, styles.borderBottom]}><Text
                                    style={styles.category2}>Publié par {projects[i].author} le {projects[i].date}</Text></View>
                                <View style={styles.flex3}>
                                    <Text style={styles.font20}>Département : {typeof projects[i].data.dpt  !== 'undefined'? projects[i].data.dpt : ''}</Text>
                                </View>
                                <View style={styles.flex3}>
                                    <Text style={styles.font20}>Nombres de lots : {typeof projects[i].data.lots !== 'undefined'? projects[i].data.lots : ''}</Text>
                                </View>
                                <View style={styles.flex3}>
                                    <Text style={styles.font20}>{typeof projects[i].data.info_bati_type_travaux !== 'undefined' ? projects[i].data.info_bati_type_travaux : ''}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </TouchableOpacity>
                );
            }
        }

    }

    if (projects.length === 0) {
        noContent = <View style={styles.itemEmpty}>
            <Text style={styles.textEmpty}>Aucune proposition</Text>
        </View>
    }

    if(!isCapeb) {
        notCapeb = <View style={styles.itemNotCapeb}>
            <Text style={styles.textEmpty}>La bourse des travaux est réservé aux adhérents de la CAPEB, vous ne pourrez pas accéder au détail des propositions</Text>
            <TouchableOpacity
                onPress={() => navigation.navigate('FormContact')} style={styles.underline}>
            <Text style={styles.textEmpty}>Contactez-nous pour adhérer</Text>
            </TouchableOpacity>
        </View>
    }

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="BOURSE DE TRAVAUX" titleTextTop="" navigation={navigation} backTo={backTo()}/>
                {loading ? (
                    <View style={[styles.containerLoader, styles.horizontal]}>
                        <ActivityIndicator size="large" color="#df5952"/>
                    </View>
                ) : (
                    <ScrollView scrollsToTop={true} style={styles.insideContainer}>
                        <TextInput
                            style={[search ? styles.input : styles.placeholderT, search.length > 0 ? styles.inputKO : styles.inputOK, styles.inputBlack]}
                            placeholder="Rechercher ..."
                            value={search}
                            placeholderTextColor={'#484848'}
                            autoCapitalize='none'
                            autoCorrect={false}
                            autoFocus={false}
                            onChangeText={(ev) => updateSearch(false, ev)}
                        />
                        {notCapeb}
                        {listProject}
                        {noContent}
                    </ScrollView>
                )}
            </View>
            <View style={isCapeb ? styles.insideElement : styles.hide }>
                <TouchableOpacity
                    onPress={() => createBourse()}
                    style={styles.roundButton2}>
                    <Image
                        style={[Platform.OS === 'ios' || Platform.OS === 'android' ? styles.icon3 : styles.iconWeb3]}
                        source={require('./../assets/images/logo-create.png')}
                    /><Text style={styles.textBold}> DÉPOSER UNE PROPOSITION</Text>
                </TouchableOpacity>
            </View>
            <Helper navigation={navigation} />
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'}/>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    imageStyle: {
        width: 100,
        height: 120,
        resizeMode: "cover"
    },
    hide: {
        display: 'none'
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    flex2: {
        flexDirection: 'row',
        padding: 2,
        marginBottom: 6
    },
    flex3: {
        flexDirection: 'row',
        paddingTop: 2,
        marginBottom: 6
    },
    text: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
    },
    textEmpty: {
        marginTop: 10,
        fontWeight: 'bold',
        fontFamily: "GothamBold",
        fontSize: 16
    },
    insideContainer: {
        backgroundColor: '#FFF',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    item: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#d0d0d0',
        flexDirection: 'row',
        width: '95%',
        borderRadius: 5,
        backgroundColor: '#222220',
        margin: 5,
        alignSelf: 'center',
    },
    itemEmpty: {
        padding: 10,
        borderBottomWidth: 0,
        borderBottomColor: '#d0d0d0',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemNotCapeb: {
        padding: 10,
        borderBottomWidth: 0,
        marginBottom: 10,
        borderBottomColor: '#d0d0d0',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    category: {
        fontSize: 18,
        fontFamily: "GothamBold",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 2,
        color: 'white',
        textTransform: 'uppercase',
    },
    category2: {
        fontSize: 14,
        fontFamily: "GothamBold",
        paddingBottom: 5,
        color: '#C0C0C0',
    },
    title: {
        fontSize: 20,
        fontFamily: "Gotham",
    },
    leftBlock: {
        paddingLeft: 10,
        flexShrink: 1
    },
    actionContainer: {
        marginTop: 20,
        marginBottom: 10,
        width: 45,
        height: 45,
        zIndex: 99999,
        left: 190,
    },
    actionBtn: {
        marginTop: 0,
        marginBottom: 10,
        width: 45,
        height: 45,
        zIndex: 9999999,
    },
    logo: {},
    horizontal: {
        flexDirection: "row",
        justifyContent: "space-around",
        padding: 10,
        marginTop: -120
    },
    containerLoader: {
        flex: 1,
        justifyContent: "center"
    },
    insideElement: {
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    roundButton2: {
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        padding: 10,
        backgroundColor: '#C6362D',
        borderRadius: 5,
        bottom: 30,
        fontFamily: "GothamBold",
        flexDirection: 'row',
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20
    },
    inputOK: {
        borderBottomColor: '#000'
    },
    inputKO: {
        borderBottomColor: '#000'
    },
    placeholder: {
        fontSize: 20,
        color: '#484848',
        fontWeight: 'normal',
        fontFamily: "Gotham",
    },
    input: {
        margin: 20,
        padding: 10,
        width: '95%',
        alignSelf: 'center',
        height: 55,
        fontSize: 20,
        fontWeight: 'bold',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    placeholderT: {
        margin: 20,
        padding: 10,
        width: '95%',
        alignSelf: 'center',
        height: 55,
        fontSize: 20,
        color: '#484848',
        fontWeight: 'normal',
        fontFamily: "Gotham",
        borderRadius: 5,
        backgroundColor: '#D9D9D9'
    },
    inputBlack: {
        color: '#000',
    },
    icon: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWeb: {
        width: 25,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon3: {
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWeb3: {
        width: 50,
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon5: {
        width: 15,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
    },
    iconWeb5: {
        width: 15,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
    },
    icon2: {
        top: 23,
        right: 10,
        position: 'absolute',
    },
    font20: {
        fontSize: 16,
        color: 'white',
        marginLeft: 0
    },
    borderBottom: {
        borderBottomColor: 'white',
        borderBottomWidth: 1
    },
    underline: {
        textDecorationLine: "underline",
    }
});

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {StyleSheet, Text, View, Image, Linking, TouchableOpacity, Platform} from 'react-native';
import {RootStackScreenProps} from "../types";
import AsyncStorage from '@react-native-async-storage/async-storage';
import HeaderMini from "../components/HeaderMiniLogged";
import FlashMessage from "react-native-flash-message";
import Helper from "../components/Helper";

export default function ContactScreen({navigation}: RootStackScreenProps<'Contact'>) {
    const isConnected = async () => {
        const access_token = await AsyncStorage.getItem('access_token')
        if (!access_token) {
            navigation.navigate('Home')
        }
    }
    useEffect(() => {
        isConnected().then()
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.flex}>
                <HeaderMini titleText="CONTACT" titleTextTop="" navigation={navigation}/>
                <View style={styles.insideContainer}>
                    <Image
                        style={styles.logo}
                        source={require('./../assets/images/capeb_blanc.png')}
                    />
                    <View style={styles.textBlock}>
                        <Text style={styles.text}>CONFÉDÉRATION DE L’ARTISANAT ET DES</Text>
                        <Text style={styles.text}>PETITES ENTREPRISES </Text>
                        <Text style={styles.text}>DU BÂTIMENT</Text>
                    </View>
                    <View style={styles.textBlock}>

                    </View>
                    <View style={styles.textBlock}>
                        <Text style={styles.textNoBold}>2 RUE BERANGER</Text>
                        <Text style={styles.textNoBold}>75140 PARIS CEDEX 03</Text>
                    </View>
                    <View style={styles.textBlock}>

                    </View>
                    <View style={styles.textBlock}>
                        <TouchableOpacity onPress={() => Linking.openURL('https://www.capeb.fr')}>
                        <Text style={styles.text}>www.capeb.fr</Text>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                        onPress={() => navigation.navigate('FormContact')}
                        style={styles.roundButton2}>
                        <Text style={styles.textBold}>FORMULAIRE DE CONTACT</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    flex: {
        flex: 1,
        flexDirection: 'column'
    },
    logo: {
        width: 200,
        height: 70,
        marginTop: 50,
        marginBottom: 25
    },
    text: {
        fontWeight: 'bold',
        fontSize: 20,
        padding: 0,
        marginTop: 0,
        fontFamily: "GothamBold",
    },
    textNoBold: {
        fontSize: 20,
        padding: 0,
        marginTop: 0,
        fontFamily: "Gotham",
    },
    textBlock: {
        padding: 15,
        width: '90%'
    },
    insideContainer: {
        flex: 1,
        alignItems: 'center',
        borderTopColor: '#d0d0d0',
        borderTopWidth: 1,
    },
    roundButton2: {
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#C6362D',
        bottom: 50,
        position: 'absolute',
        fontFamily: "GothamBold",
    },
    roundButton3: {
        width: 360,
        height: 55,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#C6362D',
        bottom: 120,
        position: 'absolute',
        fontFamily: "GothamBold",
    },
    textBold: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 20,
        fontFamily: "GothamBold",
    },
    barre: {
        left: 0
    }
});
